import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { InputField1 } from 'src/components/inputFields/InputField';
import axios from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { useDispatch } from 'react-redux';
import Validator from 'validatorjs';
import { useFetchLocationData } from './useFetchSupplierData';
import { addLocationData, updateLocationData } from 'src/redux/locationSlice';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import AddIcon from '@mui/icons-material/Add';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { addSupplierData, updateSupplierData } from 'src/redux/supplierSlice';
import { Grid } from '@mui/material';

export default function EditSupplier({
  open, setOpen, editData, actionType, setEditData
}) {

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const [supplierName, setSupplierName] = React.useState('');
  const [supplierDetails, setSupplierDetails] = React.useState([{ detail: '', code: '' }]); // Initialize with one row
  const [id, setId] = React.useState(null);
  const dispatch = useDispatch();

  const handleFormSubmit = async () => {
    const props = {
      values: {
        SupplierName: supplierName,
        SupplierDetails: supplierDetails,
      },
      type: actionType,
      idValue: id,
    }

    const newValues = supplierDetails.map((supplier, index) => {
      return { SupplierName: supplierName, SupplierDetails: supplier }
    })


    console.log(newValues)
    const validation = new Validator({ supplierName }, { supplierName: 'required' });
    if (validation.fails()) {
      toast.error(`SupplierName is required. Please enter a value.`);
      return;
    }

    const addEditGenericList = async ({ values, type, idValue }) => {
      try {
        // const response = await axios.post(`${ApiUrl2}api/Manifest/SaveSystemGenericList`, values);
        const response = { status: 200 }
        if (response.status === 200) {
          if (type === "add") {
            dispatch({
              type: addSupplierData,
              payload: newValues,
            });
          } else {
            dispatch({
              type: updateSupplierData,
              payload: { id: idValue, newData: newValues },
            });
          }
        }
        toast.success(`Location ${type === "add" ? 'Added' : 'Edit'} Successfully`);
      } catch (error) {
        console.error(error);
      }
    };

    addEditGenericList(props);
    handleClose()
  };

  useEffect(() => {
    if (editData) {
      setId(editData.id)
      setSupplierName(editData.supplierName);
      setSupplierDetails(editData.supplierDetails || [{ detail: '', code: '' }]); // Set existing details or a default row
    } else {
      setSupplierName('');
      setSupplierDetails([{ detail: '', code: '' }]); // Reset to one row
      setId(null)
    }
  }, [editData]);

  const handleAddRow = () => {
    setSupplierDetails([...supplierDetails, { detail: '', code: '' }]);
  };

  const handleDeleteRow = (index) => {
    const updatedDetails = supplierDetails.filter((_, i) => i !== index);
    setSupplierDetails(updatedDetails);
  };

  const handleRowChange = (index, field, value) => {
    const updatedDetails = supplierDetails.map((row, i) => i === index ? { ...row, [field]: value } : row);
    setSupplierDetails(updatedDetails);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h4">{editData !== null ? 'Edit' : 'Add New'} Supplier</Typography>
          <InputField1
            id="SupplierName"
            name="SupplierName"
            fullWidth
            label="Supplier Name"
            autoComplete={'on'}
            value={supplierName}
            onChange={(e) => setSupplierName(e.target.value)}
            margin="normal" 
            />
          <Grid container justifyContent="flex-end" alignItems="center" className="br-3 bg-blue ">
            <IconButton
              onClick={handleAddRow}
              aria-label="add row"
              className="mx-2 mx-xl-0 mb-1 mt-2"
              sx={{
                backgroundColor: '#e0e0e0',  // Gray background
                borderRadius: '8px',         // Square box with rounded corners
                padding: '8px',              // Adjust padding for square shape
                '&:hover': {
                  backgroundColor: '#d5d5d5', // Slightly darker gray on hover
                }
              }}
            >
              <AddIcon color="success" />
            </IconButton>
          </Grid>

          <TableContainer sx={{ maxHeight: 300, overflowY: 'auto' }}>
            <Table sx={{ border: '1px solid #ddd' }}>
              <TableHead>
                <TableRow>
                <TableCell>Supplier</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>
                  <Grid container justifyContent="flex-end" alignItems="center" className="br-3 bg-blue ">
            <IconButton
              onClick={handleAddRow}
              aria-label="add row"
              className=" mx-xl-0 mb-1"
              sx={{
                backgroundColor: '#e0e0e0',  // Gray background
                borderRadius: '8px',         // Square box with rounded corners
                padding: '8px',              // Adjust padding for square shape
                '&:hover': {
                  backgroundColor: '#d5d5d5', // Slightly darker gray on hover
                }
              }}
            >
              <AddIcon color="success" />
            </IconButton>
          </Grid>
          </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierDetails.map((item, i) => (
                  <TableRow key={i}>
                     <TableCell sx={{ minWidth: '210px', width: '210px' }}>
                        {supplierName.length >20 ? `${supplierName.slice(0,20)}...`: supplierName}
                    </TableCell>
                    <TableCell  >
                      <InputField1
                        value={item.detail}
                        label={"Description"}
                        onChange={(e) => handleRowChange(i, 'detail', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <InputField1
                        value={item.code}
                        onChange={(e) => handleRowChange(i, 'code', e.target.value)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteRow(i)} aria-label="delete row">
                        <DeleteIcon color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleFormSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
