import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { InputField1 } from 'src/components/inputFields/InputField';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { toast } from 'react-toastify';

export default function AddSupplierModal({ open, setOpen, equipmentCategoryId }) {
  const [supplierName, setSupplierName] = useState('');
  const [suppliers, setSuppliers] = useState([{ SupplierCode: '', SupplierDescription: '' }]);
  const [supplierData, setSupplierData] = useState(null);

  const handleClose = () => {
    setOpen(false);
    setSupplierName('');
    setSuppliers([{ SupplierCode: '', SupplierDescription: '' }]);
  };

  const handleAddMore = () => {
    setSuppliers([...suppliers, { SupplierCode: '', SupplierDescription: '' }]);
  };

  const handleFormSubmit = async () => {
    const sendData = {
      EquiomentCategoryId: equipmentCategoryId,
      SupplierId: supplierData.SupplierId,
      SupplierName: supplierName,
      SupplierDetails: suppliers,
    };

    try {
      const response = await axios.post(`${ApiUrl2}api/EquipmentCategory/InsertSupplierData`, sendData);
      if (response.status === 200) {
        toast.success('Suppliers Added Successfully');
        handleClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedSuppliers = [...suppliers];
    updatedSuppliers[index][field] = value;
    setSuppliers(updatedSuppliers);
  };

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `${ApiUrl2}api/EquipmentCategory/GetSupplierData?equipmentCategoryId={${equipmentCategoryId}}`
      );
      if (response.status === 200) {
        setSupplierName(response.data.SupplierName || '');
        setSuppliers(
          response?.data?.SupplierDetails?.length === 0
            ? [{ SupplierCode: '', SupplierDescription: '' }]
            : response?.data?.SupplierDetails
        );
        setSupplierData(response.data);
      }
    };
    if (open === true) {
      getData();
    }
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h4">
            Add/Edit New Suppliers
          </Typography>
          <InputField1
            fullWidth
            label="Supplier Name"
            value={supplierName}
            onChange={(e) => setSupplierName(e.target.value)}
            margin="normal"
          />
          {suppliers.map((supplier, index) => (
            <div key={index}>
              <InputField1
                fullWidth
                label={`Supplier Code ${index + 1}`}
                value={supplier.SupplierCode}
                onChange={(e) => handleInputChange(index, 'SupplierCode', e.target.value)}
                margin="normal"
              />
              <InputField1
                fullWidth
                label={`Description ${index + 1}`}
                value={supplier.SupplierDescription}
                onChange={(e) => handleInputChange(index, 'SupplierDescription', e.target.value)}
                margin="normal"
              />
            </div>
          ))}
          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleFormSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
            <SecondaryButton title="Add More" onClick={handleAddMore} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
