import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { CustomSelect, InputField1 } from 'src/components/inputFields/InputField';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { toast } from 'react-toastify';
import * as Validator from 'validatorjs';
import { MenuItem } from '@mui/material';
import { useEffect } from 'react';

export default function EquipmentEditModal({
  open,
  setOpen,
  editData,
  setEditData,
  supplierListData,
  equipmentSingleData,
  fetchEquipCatData,
}) {
  const [name, setName] = React.useState('');
  // const [description, setdescription] = React.useState('');
  const [supplierName, setSupplierName] = React.useState('');
  const [supplierCode, setSupplierCode] = React.useState('');
  const [supplierDescription, setSupplierDescription] = React.useState('');
  const [serialInitials, setSerialInitials] = React.useState('');
  const [supplierDescriptionList, setSupplierDescriptionList] = React.useState([]);
  const [supplierCodeList, setSupplierCodeList] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
    setName('');
    setSupplierName('');
    // setdescription('');
    setSupplierCode('');
    setSupplierDescription('');
    setSerialInitials('');
  };

  useEffect(() => {
    // if (equipmentSingleData !== null) {
    setName(equipmentSingleData?.EquipmentCategoryName);
    // setdescription(equipmentSingleData?.EquipmentCategoryDescription);
    const selectedSupplier = supplierListData?.find(supplier =>supplier?.SupplierId == equipmentSingleData?.SupplierId);
    //const codes = selectedSupplier?.SupplierCodes?.map((code, index) => { return { code: code, index: index } })
    const descriptions= selectedSupplier?.SupplierDescription?.map((description, index) => { return { description: description, index: index } })
    setSupplierName(selectedSupplier || {});
    setSupplierCodeList(selectedSupplier?.SupplierCodes)
    setSupplierDescriptionList(descriptions || []);
    const selectedDescription = descriptions?.find(description => description?.description == equipmentSingleData?.SupplierDescription);
    setSupplierCode(equipmentSingleData?.SupplierCode  || '');
    setSupplierDescription( selectedDescription);
    setSerialInitials(equipmentSingleData?.SerialInitials);
    // }
  }, [equipmentSingleData, supplierListData]);

  const handleFormSubmit = async () => {
    const formData = {
      EquipmentCategoryId: '',
      EquipmentCategoryName: name,
      EquipmentCategoryDescription: '',
      SupplierId: Number(supplierName?.SupplierId),
      SerialInitials: serialInitials,
      SupplierName: supplierName?.SupplierName,
      SupplierCode: supplierCode,
      SupplierDescription: supplierDescription.description,
      SupplierCodeDescriptionId: 0,
    };

    const checkdata = {
      EquipmentCategoryName: 'required',
      // EquipmentCategoryDescription: 'required',
      SupplierId: 'required',
      SerialInitials: 'required',
      SupplierCode: 'required',
      SupplierDescription: 'required',
    };

    const validation = new Validator(formData, checkdata);

    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((errorArray) => {
        errorArray.map((error) => {
          if (error.includes('EquipmentCategoryId')) {
            toast.error(`Equipment Category is required. Please enter a value.`);
          } else if (error.includes('SKNNumber')) {
            toast.error(`SKN Number is required. Please enter a value.`);
          } else if (error.includes('Engraved')) {
            toast.error(`Engraved Please enter a value.`);
          } else if (error.includes('ManufacturerNumber')) {
            toast.error(`Manufacturer Numberr is required. Please enter a value.`);
          } else if (error.includes('CertificateNumber')) {
            toast.error(`Certificate Number is required. Please select a value.`);
          } else if (error.includes('PONumber')) {
            toast.error(`PO Number is required. Please select a value.`);
          } else if (error.includes('PurchaseDate')) {
            toast.error(`Purchase Date is required. Please select a value.`);
          } else if (error.includes('PurchaseValue')) {
            toast.error(`Purchase Value is required. Please select a value.`);
          } else if (error.includes('Conformity')) {
            toast.error(`Conformity is required. Please select a value.`);
          } else if (error.includes('FirstInspectionDate')) {
            toast.error(`First Inspection Date is required. Please select a value.`);
          } else if (error.includes('LastInspectionDate')) {
            //   toast.error(`Last Inspection Date is required. Please select a value.`);
            // } else if (error.includes('NextInspectionDate')) {
            //   toast.error(`Next Inspection Date is required. Please select a value.`);
          } else if (error.includes('Status')) {
            toast.error(`Status is required. Please select a value.`);
          // } else if (error.includes('EquipmentDescription')) {
          //   toast.error(`Equipment Description is required. Please select a value.`);
          } else {
            // Display other error messages as they are
            toast.error(`${error}`);
          }
        });
      });
    } else {
      try {
        if (editData?.EquipmentCategoryId) {
          formData.EquipmentCategoryId = editData.EquipmentCategoryId;
          formData.SupplierCodeDescriptionId = equipmentSingleData?.SupplierCodeDescriptionId || 0;
        }
        const response = await axios.post(
          `${ApiUrl2}api/EquipmentCategory/InsertSingleEquipmentCategoryData`,
          formData
        );
        if (response.status === 200) {
          fetchEquipCatData();
          toast.success(`Equipment Category ${editData !== null ? 'Edited' : 'Added'} Successfully`);
          handleClose();
        }
      } catch (e) {
        toast.error(e.response.data.Message);
        console.log(e);
      }
    }
  };

  const handleSupplierNameChange = (e) => {
    const selectedSupplier = e.target.value;
    const codes = selectedSupplier?.SupplierCodes;
    const description =selectedSupplier?.SupplierDescription?.map((description, index) => { return { description: description, index: index } })
    setSupplierName(selectedSupplier || '');
    setSupplierDescriptionList(description || []);
    setSupplierCodeList(selectedSupplier?.SupplierCodes || []);
    setSupplierCode('');
    setSupplierDescription('')
  };

  const handleSupplierCodeChange = (e) => {
    const selectedCode = e.target.value || {};
    // console.log("supplierDescriptionList[selectedCode.index] ", selectedCode)

    setSupplierCode(selectedCode || {});
    setSupplierDescription(supplierDescriptionList[selectedCode.index] || '');
  };

  const handleSupplierDescriptionChange = (e) => {
    const selectedDescription = e.target.value || {};
    // console.log("supplierDescriptionList[selectedCode.index] ", selectedCode)

    setSupplierDescription(selectedDescription || {});
    setSupplierCode(supplierCodeList[selectedDescription.index] || '');
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h4">
            {editData !== null ? 'Edit' : 'Add New'} Category
          </Typography>
          <InputField1
            id="Name"
            name="Name"
            fullWidth
            label="Name"
            autoComplete={'on'}
            value={name}
            disabled={editData?.EquipmentCategoryId ? true :false}
            onChange={(e) => setName(e.target.value)}
            margin="normal"
            InputLabelProps={editData !== null ? { shrink: true } : {}}
          />
          {/* <InputField1
            id="Description"
            name="Description"
            autoComplete={'on'}
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
            margin="normal"
          /> */}
          <InputField1
            id="SerialInitials"
            name="SerialInitials"
            autoComplete={'on'}
            fullWidth
            label="Serial Initials"
            value={serialInitials}
            disabled={editData?.EquipmentCategoryId ? true :false}
            onChange={(e) => setSerialInitials(e.target.value)}
            margin="normal"
            InputLabelProps={editData !== null ? { shrink: true } : {}}
          />
          <CustomSelect
            id="SupplierName"
            name="SupplierName"
            autoComplete={'on'}
            margin="normal"
            label="Supplier Name"
            value={supplierName}
            disabled={editData?.EquipmentCategoryId ? true :false}
            onChange={handleSupplierNameChange}
            menus={
              supplierListData?.length > 0 ? (
                supplierListData.map((data, i) => (
                  <MenuItem key={i} value={data}>
                    {data.SupplierName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No data available</MenuItem>
              )}
          // disabled={editData ? true : false}
          />
          <CustomSelect
            id="SupplierDescription"
            name="SupplierDescription"
            autoComplete={'on'}
            fullWidth
            label="Supplier Description"
            value={supplierDescription}
            disabled={editData?.EquipmentCategoryId ? true :false}
            onChange={handleSupplierDescriptionChange}
            menus={
              supplierDescriptionList?.length > 0 ? (
                supplierDescriptionList?.map((data, i) => (
                  <MenuItem key={i} value={data}>
                    {data?.description}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No data available</MenuItem>
              )}
            margin="normal"
          />
          <InputField1
            id="SupplierCode"
            name="SupplierCode"
            autoComplete="on"
            margin="normal"
            fullWidth
            label="Supplier Code"
            value={supplierCode}
            onChange={handleSupplierCodeChange}
            disabled={true}
            // InputLabelProps={{ shrink: true }}
          />

          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleFormSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
