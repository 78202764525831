import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bagData: null,
  availableBag: null,
  savedBag: null,
  availableEquipment: null,
  nextBag:null
};

export const bagSlice = createSlice({
  name: 'bag',
  initialState,
  reducers: {
    getBagData: (state, action) => {
      state.bagData = action.payload;
    },
    addBagData: (state, action) => {
      const newData = { ...action.payload, id: state.bagData.length };
      state.bagData = [newData, ...state.bagData];
    },
    addUpdatedBag: (state, action) => {
      const updatedVal = action.payload;
      const newbagData = [...state.bagData];
      const element = newbagData.findIndex((elem) => elem.BagId === updatedVal.BagId);
      newbagData[element] = { ...updatedVal, id: newbagData[element].id };
      state.bagData = newbagData;
    },
    deleteBag: (state, action) => {
      state.bagData = state.bagData.filter((item) => item.BagId !== action.payload);
    },
    getAvailableBagData: (state, action) => {
      state.availableBag = action.payload;
    },

    updateAvaialbleBag: (state, action) => {
      let updatedBag = [...state.availableBag];
      const findInd = updatedBag.find((item) => item.BagId === action.payload.BagId);
      console.log(findInd);
      updatedBag[findInd] = {
        ...updatedBag[findInd],
        status: 'Bag',
      };

      state.availableBag = updatedBag;
    },
    addSavedBag: (state, action) => {
      state.savedBag = action.payload;
    },
    addNextBag: (state, action) => {
      state.nextBag = action.payload;
    },
  },
});

export const {
  getBagData,
  addBagData,
  addUpdatedBag,
  deleteBag,
  getAvailableBagData,
  updateAvaialbleBag,
  addSavedBag,
  addNextBag
} = bagSlice.actions;

export default bagSlice.reducer;
