import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addLocationData, getClientData, getLocationData, getStatusData, updateLocationData } from 'src/redux/locationSlice';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useFetchLocationData = () => {
  const locationDatas = useSelector((state) => state.location.locationData);
  const statusDatas = useSelector((state) => state.location.statusData);
  const clientDatas = useSelector((state) => state.location.clientData);
  const dispatch = useDispatch();

  const getLocationList = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}/api/Manifest/GetSystemGenericList?listName=location`);
      if (response.status === 200) {
        const Locationlist = JSON.parse(response.data).map((item, i) => {
          return { location: item, id: i };
        });
        dispatch({
          type: getLocationData,
          payload: (Locationlist),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getClientList = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}/api/Manifest/GetSystemGenericList?listName=Client`);
      if (response.status === 200) {
        const ClientList = JSON.parse(response.data).map((item, i) => {
          return { client: item, id: i };
        });
        dispatch({
          type: getClientData,
          payload: (ClientList),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusList = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}/api/Manifest/GetSystemGenericList?listName=Status`);
      if (response.status === 200) {
        const statusList = JSON.parse(response.data).map((item, i) => {
          return { status: item, id: i };
        });
        dispatch({
          type: getStatusData,
          payload: (statusList),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    locationDatas,
    statusDatas,
    clientDatas,
    getLocationList,
    getClientList,
    getStatusList,
  };
};
