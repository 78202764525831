import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment, FormControlLabel, Typography, Card, Grid } from '@mui/material';
import Iconify from '../../components/iconify';
import { ApiUrl } from 'src/utils/ApiUrl';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { login } from 'src/redux/adminSlice';
import Validator from 'validatorjs';
import { toast } from 'react-toastify';
import { CustomCheck, InputField1 } from 'src/components/inputFields/InputField';
import { PrimaryButton } from 'src/components/buttons/MyButton';
import { Link1 } from 'src/components/links/MyLink';
import useResponsive from 'src/hooks/useResponsive';
// import { SetLocalSession, StoredVariables } from 'src/utils/SecureStorage';

// ----------------------------------------------------------------------

export default function LoginForm() {
  // const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const mdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();

  const handleClick = async () => {
    if (email === 'admin@gmail.com' && password === 'Admin@123') {
      dispatch({ type: login, payload: 'true' });
    } else {
      toast.error('Invalid Credentials');
    }
    // const checkdata = {
    //   email: 'required|email',
    //   password: 'required',
    // };
    // setLoading(true);

    // const loginData = { email, password };

    // const validation = new Validator(loginData, checkdata);

    // if (validation.fails()) {
    //   const errorData = Object.values(validation.errors.errors);
    //   errorData.map((x) => toast.error(`${x}`));
    //   setLoading(true);
    // } else {
    //   try {
    //     const response = await axios.post(`${ApiUrl}admin/signin`, { email, password });
    //     if (response.status === 200) {
    //       toast.success('Login Success');
    //       navigate('/dashboard', { replace: true });
    //       dispatch({ type: login, payload: response.data });
    //       setLoading(false);
    //       if (checked === true) {
    //         SetLocalSession(StoredVariables.isRememberMe, { email, password });
    //       }
    //     }
    //   } catch (e) {
    //     toast.error(e.response.data.message);
    //     setLoading(false);
    //   }
    // }
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <>
      <Stack spacing={3} minWidth={mdUp  ? "320px":"300px"}  style={{ padding: "30px" }}>
        
       
      <Typography variant="h4" gutterBottom sx={{pb:3}}>
      SKN Equipment Database
            </Typography>
        <InputField1 
          id="email" 
          autoComplete={'on'} 
          name="email" 
          label="Email address" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          onKeyDown={handleKeyDown}
          InputLabelProps={email !== null ? { shrink: true } : {}}
        />
        <InputField1
          id="password"
          name="password"
          value={password}
          autoComplete={'on'}  
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          onKeyDown={handleKeyDown}
          InputLabelProps={password !== null ? { shrink: true } : {}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
         {/* <Stack direction="row" alignItems="center" justifyContent="space-between" > */}
        {/* <FormControlLabel
          control={<CustomCheck checked={checked} onChange={() => setChecked(!checked)} />}
          label="Remember me"
        /> */}
        <PrimaryButton title="login" fullWidth size="large"  onClick={handleClick} loading={loading} />
      {/* </Stack> */}
    
      </Stack>

     
    </>
  );
}
