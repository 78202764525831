import { Button, CircularProgress, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './button.css';
import { styled } from '@mui/material/styles';

export const ColorButton = styled(Button)(() => ({
  color: '#fff',
  backgroundColor: `var(--501)`,
  '&:hover': {
    backgroundColor: `var(--501)`,
  },
}));

export const OutlineButton = styled(Button)(() => ({
  color: `var(--501)`,
  border: `2px solid var(--501)`,
  '&:hover': {
    backgroundColor: `#fff`,
  },
}));

const ColorButton2 = styled(Button)(() => ({
  color: '#fff',
  backgroundColor: `var(--500)`,
  '&:hover': {
    backgroundColor: `var(--500)`,
  },
}));

export const EditButton = ({ onClick }) => {
  return (
    <IconButton onClick={onClick && onClick} color="success">
      <EditIcon />
    </IconButton>
  );
};

export const DeleteButton = ({ onClick, color="error" }) => {
  return (
    <IconButton onClick={onClick && onClick} color={color}>
      <DeleteIcon />
    </IconButton>
  );
};

export const PrimaryButton = ({ onClick, title, classNames, fullWidth, size, loading, id="button" }) => {
  return (
    <ColorButton
    id={id}
      disabled={loading && true}
      className={`${classNames} primaryButton`}
      onClick={onClick && onClick}
      fullWidth={fullWidth && fullWidth}
      size={size && size}
    >
      {loading ? <CircularProgress sx={{ color: '#fff', width: '20px', height: '20px', fontSize: '10px' }} /> : title}
    </ColorButton>
  );
};
export const PrimaryButtonOutlined = ({ onClick, title, classNames, fullWidth, size, id="button" }) => {
  return (
    <OutlineButton
    id={id}
      className={`${classNames} secondaryButton`}
      onClick={onClick && onClick}
      fullWidth={fullWidth && fullWidth}
      size={size && size}
    >
      {title}
    </OutlineButton>
  );
};

export const SecondaryButton = ({ onClick, title, classNames, fullWidth, size, id="button" }) => {
  return (
    <ColorButton2
    id={id}
      className={`${classNames} primaryButton`}
      onClick={onClick && onClick}
      fullWidth={fullWidth && fullWidth}
      size={size && size}
    >
      {title}
    </ColorButton2>
  );
};
