import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locationData: null,
  statusData: null,
  clientData: null,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    getClientData: (state, action) => {
      state.clientData = action.payload;
    },
    getLocationData: (state, action) => {
      state.locationData = action.payload;
    },
    getStatusData: (state, action) => {
        state.statusData = action.payload;
    },
    addLocationData: (state, action) => {
      const newData = { location : action.payload, id: state.locationData.length };
      state.locationData = [newData, ...state.locationData];
    },
    updateLocationData: (state, action) => {
      const { id, newData } = action.payload;
      state.locationData = state.locationData.map(location => {
        if (location.id === id) {
          return { ...location, location: newData };
        }
        return location;
      });
    },
    addStatusData: (state, action) => {
      const newData = { status : action.payload, id: state.statusData.length };
      state.statusData = [newData, ...state.statusData];
    },
    updateStatusData: (state, action) => {
      const { id, newData } = action.payload;
      state.statusData = state.statusData.map(status => {
        if (status.id === id) {
          return { ...status, status: newData };
        }
        return status;
      });
    },
    addClientData: (state, action) => {
      const newData = { client : action.payload, id: state.clientData.length };
      state.clientData= [newData, ...state.clientData];
    },
    updateClientData: (state, action) => {
      const { id, newData } = action.payload;
      state.clientData = state.clientData.map(client => {
        if (client.id === id) {
          return { ...client, client: newData };
        }
        return client;
      });
    }
  },
});

export const {
    getStatusData,
    getLocationData,
    getClientData,
    updateLocationData,
    addLocationData,
    updateStatusData,
    addStatusData,
    addClientData,
    updateClientData
} = locationSlice.actions;

export default locationSlice.reducer;
