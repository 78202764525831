import { Container } from '@mui/material';
import React from 'react';
import Quote from './quote/Quote';

const HomePage = () => {
  return (
    <>
      <Container maxWidth="xl">
        <Quote />
      </Container>
    </>
  );
};

export default HomePage;
