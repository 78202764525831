import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStylemoreWidthwithAutoHeight } from 'src/utils/cssStyles';
import { CustomSelect, InputField1 } from 'src/components/inputFields/InputField';
import { useEffect } from 'react';
import { CustomDate } from 'src/components/date_picker/CustomDatePicker';
import dayjs from 'dayjs';
import { Grid, MenuItem } from '@mui/material';

export default function EquipmentDetailModal({ open, setOpen, equipmentCategoryDatas, eqDetail, setEqDetail }) {
  const handleClose = () => {
    setOpen(false);
    setEqDetail(null);
  };

  const [engraved, setEngraved] = React.useState('');
  const [manufacturerNumber, setManufacturerNumber] = React.useState('');
  const [manufacturerName, setManufacturerName] = React.useState('');
  const [certificateNumber, setCertificateNumber] = React.useState('');
  const [poNumber, setPoNumber] = React.useState('');
  const [purchaseDate, setPurchaseDate] = React.useState(dayjs('2022-04-17'));
  const [purchaseValue, setPurchaseValue] = React.useState('');
  const [conformity, setConformity] = React.useState('');
  const [status, setStatus] = React.useState('');
  //   const [equipmentCategory, setEquipmentCategory] = React.useState(equipmetTypeData[0].value);
  const [equipmentCategory2, setEquipmentCategory2] = React.useState(
    equipmentCategoryDatas?.[0]?.EquipmentCategoryId || ''
  );

  //   console.log(equipmentCategoryDatas, eqDetail);

  useEffect(() => {
    if (eqDetail !== null) {
      setEngraved(eqDetail.Engraved || '');
      setManufacturerNumber(eqDetail.ManufacturerNumber || '');
      setManufacturerName(eqDetail.ManufacturerName || '');
      setCertificateNumber(eqDetail.CertificateNumber || '');
      setPoNumber(eqDetail.PONumber || '');
      setPurchaseDate(dayjs(eqDetail.PurchaseDate) || '');
      setPurchaseValue(eqDetail.PurchaseValue || '');
      setConformity(eqDetail.Conformity || '');
      setStatus(eqDetail.Status || '');
      //   setEquipmentCategory(eqDetail.EquipmentCategoryId || '');

      const findCategory2 = equipmentCategoryDatas.find(
        (item) => item.EquipmentCategoryId === eqDetail.EquipmentCategoryId
      );
      if (findCategory2) {
        setEquipmentCategory2(findCategory2.EquipmentCategoryId || '');
      }
      //   console.log(findCategory2);
    } else {
      setEngraved('');
      setManufacturerNumber('');
      setManufacturerName('');
      setCertificateNumber('');
      setPoNumber('');
      setPurchaseDate(dayjs('2022-04-17'));
      setPurchaseValue('');
      setConformity('');
      setStatus('');

      setEquipmentCategory2(equipmentCategoryDatas?.[0]?.EquipmentCategoryId || '');
    }
  }, [eqDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  //   const handleFormSubmit = async () => {
  //     const formData = {
  //       Engraved: engraved,
  //       ManufacturerNumber: manufacturerNumber,
  //       ManufacturerName: manufacturerName,
  //       CertificateNumber: certificateNumber,
  //       PoNumber: poNumber,
  //       PurchaseDate: purchaseDate,
  //       PurchaseValue: purchaseValue * 1,
  //       EquipmentCategoryId: equipmentCategory2,
  //       Conformity: conformity,
  //       Status: status,
  //     };

  //     if (typeof eqDetail?.EquipmentId !== 'undefined') {
  //       formData.EquipmentId = editData.EquipmentId;
  //     }

  //     try {
  //       const response = await axios.post(`${ApiUrl2}api/equipment/InsertSingleEquipmentData`, formData);

  //       if (response.status === 200) {
  //         toast.success(`Equipment ${editData !== null ? 'Edit' : 'Added'} Successfully`);
  //         const parseData = JSON.parse(response.data);
  //         if (typeof editData?.EquipmentId !== 'undefined') {
  //           const dateString = formData.PurchaseDate;
  //           const dateObj = new Date(dateString);
  //           const formattedDate = dateObj.toLocaleDateString('en-UK', {
  //             month: '2-digit',
  //             day: '2-digit',
  //             year: 'numeric',
  //           });

  //           formData.PurchaseDate = formattedDate;
  //           dispatch({ type: addUpdatedEquipment, payload: formData });
  //           // fetchEquipData();
  //         } else {
  //           dispatch({
  //             type: addEquipmentData,
  //             payload: parseData,
  //           });
  //         }

  //         handleClose();
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStylemoreWidthwithAutoHeight}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <CustomSelect
                disabled
                margin="normal"
                label="Equipment Category"
                value={equipmentCategory2}
                onChange={(e) => setEquipmentCategory2(e.target.value)}
                menus={
                  equipmentCategoryDatas?.length > 0 &&
                  equipmentCategoryDatas.map((data, i) => (
                    <MenuItem key={i} value={data.EquipmentCategoryId}>
                      {data.EquipmentCategoryName}
                    </MenuItem>
                  ))
                }
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                label="Engraved"
                value={engraved}
                onChange={(e) => setEngraved(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                label="Manufacturer Number"
                value={manufacturerNumber}
                onChange={(e) => setManufacturerNumber(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                label="Manufacturer Name"
                value={manufacturerName}
                onChange={(e) => setManufacturerName(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                label="Certificate Number"
                value={certificateNumber}
                onChange={(e) => setCertificateNumber(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                label="PO Number"
                value={poNumber}
                onChange={(e) => setPoNumber(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6} sx={{ marginTop: '8px' }}>
              <CustomDate
                readOnly
                fullWidth
                label="Purchase Date"
                value={purchaseDate}
                onChange={(e) => setPurchaseDate(e)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                type="number"
                fullWidth
                label="Purchase Value"
                value={purchaseValue}
                onChange={(e) => setPurchaseValue(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                label="Conformity"
                value={conformity}
                onChange={(e) => setConformity(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <InputField1
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                margin="normal"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
