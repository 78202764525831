import { Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import UploadExcelModal from './UploadExcelModal';
import { useNavigate } from 'react-router-dom';
import DataTable from 'src/components/datatable/DataTable';
import UploadModal from './UploadModal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { deleteManifest } from 'src/redux/manifestSlice';
import DeleteBox from 'src/components/deleteBox/DeleteBox';
import { useGetAvailableData } from './useGetAvailableData';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import ExportModal from './ExportModal';
import UploadAuditModal from './UploadAuditModal';
import { Modal, Button, Box } from '@mui/material';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { CustomToolBarButton } from './CustomToolBarButton';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const ManifestList = () => {
  const [openuploadModal, setOpenUploadModal] = useState(false);
  const [ncrupload, setNcrUpload] = useState(false);
  const manifestList = useSelector((state) => state.manifest.manifestData);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const { fetchEquipData } = useGetAvailableData();
  const [auditModal, setAuditModal] = useState(false);
  const { paginationModel, setPaginationModel } = useDefaultPagination();
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadRope, setUploadRope] = useState(false);
  const [upload, setUpload] = useState(false);

  // console.log(manifestList);

  const handleUploadHistory = () => {
    setAuditModal(true);
  };

  useEffect(() => {
    // if (manifestList === null) {
    fetchEquipData();
    // }
  }, []);

  useEffect(() => {
    if (manifestList === null) {
      fetchEquipData();
    }
  }, [manifestList]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(manifestList);
  const handleRopeKitListNameClick = (rowData) => {
    // Implement logic or call functions related to the click event
    // In this case, I'll call handleEdit
    handleEdit(rowData);
  };
  const navigate = useNavigate();

  const columns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowIndex = params.id;
        return (
          <input
            type="checkbox"
            id={rowIndex}
            checked={editData?.ManifestId === params?.row?.ManifestId}
            onChange={() => handleModalOpen(editData?.ManifestId === params?.row?.ManifestId ? null : params.row)}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'RopeKit Name',
      width: 240,
      sortable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => handleRopeKitListNameClick(params.row)}
        >
          {params.row.ManifestName}
        </div>
      ),
      valueGetter: (params) => params.row.ManifestName, // Add valueGetter to provide sortable values
    },
    {
      field: 'location',
      headerName: 'Location',
      sortable: true,
      width: 180,
      valueGetter: (params) => params.row.Location,
    },
    {
      field: 'client',
      headerName: 'Client',
      sortable: true,
      width: 180,
      valueGetter: (params) => params.row.Client,
    },
    {
      field: 'startdate',
      headerName: 'Last Inspection date',
      sortable: true,
      width: 200,
      valueGetter: (params) => dayjs(params.row.LastInspectionDate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1Parts = v1.split('-').map(part => parseInt(part, 10));
        const date2Parts = v2.split('-').map(part => parseInt(part, 10));

        if (date1Parts[2] !== date2Parts[2]) {
          return date1Parts[2] - date2Parts[2];
        }

        if (date1Parts[1] !== date2Parts[1]) {
          return date1Parts[1] - date2Parts[1];
        }

        return date1Parts[0] - date2Parts[0];
      },
    },

    {
      field: 'enddate',
      headerName: 'Next Inspection date',
      sortable: true,
      width: 200,
      valueGetter: (params) => dayjs(params.row.NextInspectionDate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1Parts = v1.split('-').map(part => parseInt(part, 10));
        const date2Parts = v2.split('-').map(part => parseInt(part, 10));

        if (date1Parts[2] !== date2Parts[2]) {
          return date1Parts[2] - date2Parts[2];
        }

        if (date1Parts[1] !== date2Parts[1]) {
          return date1Parts[1] - date2Parts[1];
        }

        return date1Parts[0] - date2Parts[0];
      },
    },
    {
      field: 'Comments',
      headerName: 'Comments',
      sortable: true,
      width: 180,
      valueGetter: (params) => params.row.Comments,
    },
    // {
    //   field: 'action',
    //   headerName: 'Action',
    //   width: 150,

    //   renderCell: (params) => (
    //     <div style={{ gap: '10px' }} className="d-flex">
    //       {/* Only one button in the table */}
    //       <PrimaryButton title="Actions" onClick={() => handleModalOpen(params.row)} />
    //     </div>
    //   ),
    // },
  ];
  const handleModalOpen = (data) => {
    setEditData(data); // Store the data you need for modal actions
    // setModalOpen(true); // Open the modal
  };

  const AddExcelNCRRope = (rope) => {
    setUpload(true);
    if (rope === 'rope') {
      setUploadRope(true);
    }
    //
  };

  const handleModalAction = (actionType) => {
    switch (actionType) {
      case 'Edit':
        handleEdit(editData);
        break;
      case 'Upload SKNF021':
        AddExcelNCRRope('rope')
        break;
      case 'Download SKNF021':
        handleDownloadManifest(editData);
        break;
      case 'Upload SKNF018':
        setNcrUpload(true);
        break;
      case 'Download SKNF018':
        handleDownloadNCR(editData);
        break;
      case 'Delete':
        handleDeleteRow(editData);
        break;
      default:
        break;
    }
    setModalOpen(false); // Close the modal after handling the action
  };

  const handleDownloadManifest = (rowData) => {
    if (!rowData) {
      toast.error(`Please select row`);
      return;
    }
    fetch(`${ApiUrl2}api/Manifest/DownloadManifestExcel?manifestId=${rowData.ManifestId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentDisposition = response.headers.get('content-disposition');
        const filename = `RopeKit_${rowData.ManifestName}.xlsx`;

        response.blob().then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
        // Handle the error, e.g., show an error message to the user
      });
  };

  const handleDownloadNCR = (rowData) => {
    if (!rowData) {
      toast.error(`Please select row`);
      return;
    }
    fetch(`${ApiUrl2}api/Manifest/DownloadNCRExcel?manifestId=${rowData.ManifestId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        console.log("rowData", rowData)

        const contentDisposition = response.headers.get('content-disposition');
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
        const filename = filenameMatch ? filenameMatch[1] : `NCR_${rowData.ManifestName}.xlsx`;
        response.blob().then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
        // Handle the error, e.g., show an error message to the user
      });
  };

  const handleEdit = (data) => {
    if (data) {
      navigate(`/dashboard/ropekit/?id=${data.ManifestId}`);
    } else {
      toast.error(`Please select row`);
    }
  };

  const handleDeleteRow = (row) => {
    if (row) {
      setDeleteOpen(true);
      setDeleteId(row.ManifestId);
    } else {
      toast.error(`Please select row`);
    }
  };

  const actions = ['Download SKNF021', 'Upload SKNF021', 'Download SKNF018', 'Upload SKNF018', 'Delete'];
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap" style={{ gap: '10px' }}>
        <Typography variant="h4" className="mx-2 mx-xl-0 ">
          RopeKit List
        </Typography>
        <div className="d-flex align-items-center flex-wrap" style={{ gap: '10px' }}>
          <PrimaryButton
            title="Add RopeKit  Manually"
            classNames="mx-2 mx-xl-0 "
            onClick={() => navigate('/dashboard/ropekit')}
          />
          {/* <PrimaryButton title="Upload excel" classNames="mx-2 mx-xl-0 " onClick={() => setOpenUploadModal(true)} /> */}
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap" style={{ gap: '10px' }}></div>
      <div className="mt-3">
        <DataTable
          tableName='RopeKit'
          columns={columns}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          rows={manifestList || []}
          renderEmptyCell={() => <h1>No data Found</h1>}
          toolbarNotRequired
          filterMode="client"
          CutomToolBarButton={<CustomToolBarButton actions={actions} handleModalAction={handleModalAction} />}
        />
      </div>

      <UploadExcelModal open={openuploadModal} setOpen={setOpenUploadModal} fetchEquipData={fetchEquipData} />
      <UploadModal open={ncrupload} setOpen={setNcrUpload} />
      <DeleteBox
        open={deleteOpen}
        setOpen={setDeleteOpen}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        url={`${`api/Manifest/DeleteManifestData?ManifestId={${deleteId}}`}`}
        dispatchData={() => dispatch({ type: deleteManifest, payload: deleteId })}
      />
      <ExportModal open={exportModal} setOpen={setExportModal} editData={editData} />
      <UploadAuditModal open={auditModal} setOpen={setAuditModal} editData={editData} />
      <UploadModal
        open={upload}
        setOpen={setUpload}
        fetchEquipData={fetchEquipData}
        uploadRope={uploadRope}
        setUploadRope={setUploadRope}
        // setValue={setValue}
        //manifestId={manifestId}
      />
    </>
  );
};

export default ManifestList;
