import { Grid, Typography } from '@mui/material';
import Button from 'src/theme/overrides/Button';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';

export const Heading1 = ({ title, classNames }) => {
  return (
    <Typography variant="h4" className={`${classNames && classNames}`}>
      {title}
    </Typography>
  );
};

export const Heading2 = ({ title, classNames }) => {
  return (
    <Typography variant="h5" className={`${classNames && classNames}`}>
      {title}
    </Typography>
  );
};


export const Heading3 = ({ title, classNames, action , buttonSize='small'}) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" className="mb-3">
      <Typography variant="h5" className={`${classNames && classNames}`}>
        {title}
      </Typography>
      <PrimaryButton
        title="Add New"
        size= { buttonSize || "small"}
        classNames="mx-2 mx-xl-0 mb-3"
        onClick={action}
      />
    </Grid>
  );
};
