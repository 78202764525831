import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  datePassedData: null,
  nextweekInspection: null,
};

export const dashBoardSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    getDatePasseddata: (state, action) => {
      state.datePassedData = action.payload;
    },
    getnextweekInspectiondata: (state, action) => {
      state.nextweekInspection = action.payload;
    },
    // addEquipmentData: (state, action) => {
    //   const newData = { ...action.payload, id: state.equipmentData.length };
    //   state.equipmentData = [newData, ...state.equipmentData];
    // },
    // deleteEquipment: (state, action) => {
    //   state.equipmentData = state.equipmentData.filter((item) => item.EquipmentId !== action.payload);
    // },
    // addUpdatedEquipment: (state, action) => {
    //   const updatedVal = action.payload;
    //   const newequipmentData = [...state.equipmentData];
    //   const element = newequipmentData.findIndex((elem) => elem.EquipmentId === updatedVal.EquipmentId);
    //   newequipmentData[element] = { ...updatedVal, id: newequipmentData[element].id };
    //   state.equipmentData = newequipmentData;
    // },
    // getAvailableEquipmentData: (state, action) => {
    //   state.availableEquipment = action.payload;
    // },
    // addSavedEquipment: (state, action) => {
    //   state.savedEquipment = action.payload;
    // },
  },
});

export const {
  getDatePasseddata,
  getnextweekInspectiondata,
  getAvailableEquipmentData,
  getEquipmentData,
  addEquipmentData,
  deleteEquipment,
  addUpdatedEquipment,
  addSavedEquipment,
} = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
