import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getSupplierData } from 'src/redux/supplierSlice';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useFetchSupplierData = () => {
  const supplierDatas = useSelector((state) => state.supplier.supplierData);
  const dispatch = useDispatch();

  const getSupplierList = async () => {
    try {
      // const response = await axios.get(`${ApiUrl2}/api/Manifest/GetSystemGenericList?listName=location`);
      // if (response.status === 200) {
      //   const Locationlist = JSON.parse(response.data).map((item, i) => {
      //     return { location: item, id: i };
      //   });
      const SupplierList=[];
        dispatch({
          type: getSupplierData,
          payload: (SupplierList),
        });

    } catch (error) {
      console.error(error);
    }
  };

  return {
    supplierDatas,
    getSupplierList,
  };
};
