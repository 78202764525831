import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  manifestData: null,
  editData: null,
  lastInspectionDate:dayjs(new Date()),
  nextInspectionDate:dayjs(new Date()),
  locationData:[],
  clientData:[]
};

export const manifestSlice = createSlice({
  name: 'manifest',
  initialState,
  reducers: {
    getmanifestData: (state, action) => {
      state.manifestData = action.payload;
    },
    getLocationData: (state, action) => {
      state.locationData = action.payload;
    },
    getClientData: (state, action) => {
      state.clientData = action.payload;
    },
    addmanifestData: (state, action) => {
      const newData = { ...action.payload, id: state.manifestData.length };
      state.manifestData = [newData, ...state.manifestData];
    },
    updatemanifestData: (state, action) => {
      const updatedVal = action.payload;
      const newmanifestData = [...state.manifestData];
      const element = newmanifestData.findIndex((elem) => elem.ManifestId === updatedVal.ManifestId);
      // console.log(element);
      newmanifestData[element] = { ...updatedVal, id: newmanifestData[element].ManifestId };
      state.manifestData = newmanifestData;
    },
    deleteManifest: (state, action) => {
      state.manifestData = state.manifestData.filter((item) => item.ManifestId !== action.payload);
    },
    setLastInspectionDate:(state, action) => {
      state.lastInspectionDate = action.payload;
    },
    setNextInspectionDate:(state, action) => {
      state.nextInspectionDate = action.payload;
    },
  },
});

export const { getmanifestData, addmanifestData, updatemanifestData, deleteManifest, getLocationData, getClientData, setLastInspectionDate,   setNextInspectionDate } = manifestSlice.actions;

export default manifestSlice.reducer;
