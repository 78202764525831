import * as React from 'react';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import {
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
  TextField,
  Autocomplete
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PrimaryButton } from 'src/components/buttons/MyButton';
import DataTable from 'src/components/datatable/DataTable';
import { Padding } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import axios from 'axios';

const bagData = [
  {
    "BagName": "166",
    "BagDescription": "Kit Bag",
    "BagReplacement": [
      {
        "BagName": "226",
        "BagId": "b3b1662b-b63f-4176-b227-032ab22b5cf0",
        "BagDescription": "Kit Bag",
        "BagState": null
      },
      {
        "BagName": "413",
        "BagId": "e8bb80f9-0979-4334-ab74-03aa959c4e67",
        "BagDescription": "Kit Bag",
        "BagState": null
      },
      {
        "BagName": "325",
        "BagId": "28107603-bdd9-426a-81bb",
        "BagDescription": "Kit Bag",
        "BagState": null
      }
    ]
  },
  {
    "BagName": "166",
    "BagDescription": "Kit Bag",
    "BagReplacement": [
      {
        "BagName": "226",
        "BagId": "b3b1662b-b63f-4176-b227-032ab22b5cf0",
        "BagDescription": "Kit Bag",
        "BagState": null
      },
      {
        "BagName": "413",
        "BagId": "e8bb80f9-0979-4334-ab74-03aa959c4e67",
        "BagDescription": "Kit Bag",
        "BagState": null
      },
      {
        "BagName": "325",
        "BagId": "28107603-bdd9-426a-81bb",
        "BagDescription": "Kit Bag",
        "BagState": null
      }
    ]
  }
];


export default function MoreAvailableBagModal({ open, setOpen, type,manifestIdValue, getSingleManifestData}) {
  const handleClose = () => setOpen(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });

  const [selectedReplacements, setSelectedReplacements] = React.useState({});

  // const handleReplacementChange = (bagId, replacementId) => {
  //   setSelectedReplacements({
  //     ...selectedReplacements,
  //     [bagId]: replacementId,
  //   });
  // };

  const handleReplacementChange = (bagId, replacementId) => {
    const isDuplicate = Object.values(selectedReplacements).includes(replacementId);
    if (isDuplicate) {
      toast.error("Replacement already selected. Please chose another one.");
    } else {
      setSelectedReplacements({
        ...selectedReplacements,
        [bagId]: replacementId,
      });
    }
  };

  const getSelectedReplacements  = () => {
    return Object.entries(selectedReplacements).map(([bagId, replacementId]) => ({
      "OldBagId": bagId,
     "NewBagId": replacementId
    }));

   
  };

  const handleReplace = () => {
    const replacemntData= getSelectedReplacements()
    const values={
      "manifestId": manifestIdValue,
      "Replacements": replacemntData
  }

    const menifestrequremnt = async (values) => {
      const response = await axios.post(`${ApiUrl2}/api/Manifest/ReplaceBags`, values);
      if (response.status === 200) {
        toast.success("Replaced bags successfully.");
        getSingleManifestData(manifestIdValue);
      } else {
        toast.error("Error in replacing bags.");
      }
    }
  
    menifestrequremnt(values);
    handleClose()

  }

  const columns = [
    { field: 'BagName', headerName: 'Bag Name', width: 250 },
    { field: 'BagDescription', headerName: 'Bag Description', width: 300 },
    {
      field: 'Replacement',
      headerName: 'Replacement',
      width: 300,
      renderCell: (params) => (
        <FormControl fullWidth >
          <Autocomplete
            value={params.row.BagReplacement.find(replacement => replacement.BagId === selectedReplacements[params.row.BagId]) || null}
            onChange={(e, newValue) => handleReplacementChange(params.row.BagId, newValue ? newValue.BagId : '')}
            options={params.row.BagReplacement}
            getOptionLabel={(option) => option.BagName || ''}
            renderInput={(params) => <TextField {...params} placeholder="Select Replacement" variant="outlined" />}
            isOptionEqualToValue={(option, value) => option.BagId === value.BagId}
            sx={{ p: 0 }}
          />
        </FormControl>
      ),
    },
  ];


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h4" className="mb-3">
          Available Bags
        </Typography>
        <div style={{ height: 350, width: '100%' }}>
                            <DataTable
                    tableName='Equipment'
                    rows={ type }
                    columns={columns}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    renderEmptyCell={() => <h1>No data Found</h1>}
                    toolbarNotRequired
                    filterMode="client"
                    height={350}
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <PrimaryButton title={`Replace`} onClick={ handleReplace  } />
        </div>
      </Box>
    </Modal>
  );
}
