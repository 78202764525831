import DataTable from 'src/components/datatable/DataTable';
import { Heading2, Heading3 } from 'src/components/headings/Heading';
import { Grid, Paper, Typography } from '@mui/material';
// import { useFetchCategoryData } from 'src/pages/equipment_category/usefetchEquipmentData';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditLocation from './EditLocation';
import { useFetchLocationData } from './useFetchLocationData';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import axios from 'axios';
import { useSelector } from 'react-redux';
import EditClient from './EditClient';

export default function ClientTable() {
  const [open, setOpen] = useState(false);
  const { clientDatas, getClientList } = useFetchLocationData();
  const [client, setClient] = useState(null);
  const { paginationModel, setPaginationModel } = useDefaultPagination();
  const [action , setAction]=useState("add")

  useEffect(() => {
    if (clientDatas === null) {
      getClientList();
    }
  }, []);

  const columns = [
    {
      field: 'Client',
      headerName: 'Client',
      width:  240,
      valueGetter: (params) => `${params.row.client || ''} `,
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      filterable: false,
      disableExport: true,
      renderCell: (params) => (
        <div>
          <EditIcon
            sx={{ color: `var(--500)`, cursor: 'pointer', marginRight: '8px' }}
            onClick={() => handleEdit(params.row)}
          />
          {/* <DeleteIcon
            sx={{ color: `var(--500)`, cursor: 'pointer' }}
            onClick={() => handleDelete(params.row)}
          /> */}
        </div>
      ),
    },
  ];

  const handleEdit = (data) => {
    setOpen(true);
    setClient({...data})
    setAction("edit")
    
  };

  const handleDelete = (id) => {
    // Implement your delete logic here
    console.log('Deleting row with id:', id);
  };

  const handleAdd = (id) => {
    // Implement your delete logic here
    setOpen(true)
    setAction("add")
    console.log('add', id);
  };

  const customOptions = {
    exportButton: false,
    search: false,
    filter: false,
    density: false,
    columnsButton: false,
    pagination: false,
  };

  return (
    <>
      <Paper className="p-3">
        <div className="mt-4">

          <Heading3 title="Client" classNames="mb-4" action={handleAdd} />
          { clientDatas !== null && (
            <DataTable
             tableName='Clients'
              columns={columns}
              rows={clientDatas}
              toolbarNotRequired
              filterMode="Client"
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              customOptions={customOptions}
              isGenericPage={true}
            />
          )}
        </div>
      </Paper>
      <EditClient
        open={open}
        setOpen={setOpen}
        editData={client}
        setEditData={setClient}
        actionType={action}
      />
    </>
  );
}
