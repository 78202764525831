import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  equipmentCategoryData: null,
};

export const equipmentCategorySlice = createSlice({
  name: 'equipmentCategory',
  initialState,
  reducers: {
    getEquipmentCategoryData: (state, action) => {
      state.equipmentCategoryData = action.payload;
    },
    addEquipmentCategoryData: (state, action) => {
      const newData = { ...action.payload, id: state.equipmentCategoryData.length };
      state.equipmentCategoryData = [newData, ...state.equipmentCategoryData];
    },
    deleteEquipmentCategory: (state, action) => {
      state.equipmentCategoryData = state.equipmentCategoryData.filter(
        (item) => item.EquipmentCategoryId !== action.payload
      );
    },
    addUpdatedEquipmentCategory: (state, action) => {
      const updatedVal = action.payload;
      const newequipmentCategoryData = [...state.equipmentCategoryData];
      const element = newequipmentCategoryData.findIndex(
        (elem) => elem.EquipmentCategoryId === updatedVal.EquipmentCategoryId
      );
      console.log(element);

      newequipmentCategoryData[element] = { ...updatedVal, id: newequipmentCategoryData[element].id };
      state.equipmentCategoryData = newequipmentCategoryData;
    },
  },
});

export const {
  getEquipmentCategoryData,
  addEquipmentCategoryData,
  deleteEquipmentCategory,
  addUpdatedEquipmentCategory,
} = equipmentCategorySlice.actions;

export default equipmentCategorySlice.reducer;
