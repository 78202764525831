import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useFetchfilterEquipment = () => {
  const equipmentDatas = useSelector((state) => state.equipment.equipmentData);
  const allEquipmentDatas = useSelector((state) => state.equipment.allEqupmentData);
  const [searchText, setSearchText] = useState('');
  const [filterPaginationModel, setFilterPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [latestPage, setLatestPage] = useState(0);
  const [filterArray, setFilterArray] = useState(null);
  const [filterLoading, setFilterloading] = useState(false);
  const [filterEquipData, setFilerEquipData] = useState(null);
  const filterData = filterArray?.items?.[0];

  const fetchFilterEquipData = async () => {
    setFilterloading(true);
    try {
      const response = await axios.get(
        `${ApiUrl2}api/Equipment/GetEquipmentDataListColumnFilter?pageNumber=${latestPage + 1}&pageSize=10&searchText=${
          filterData?.value
        }&searchColumn=${filterData?.field}&operatorName=${filterData?.operator}`
      );
      setFilterloading(true);
      if (response.status === 200) {
        const receivedData = JSON.parse(response.data).Data.map((item, i) => {
          // const PurchasedateString = item.PurchaseDate;
          // const dateObj = new Date(PurchasedateString);
          // const formattedDate = dateObj.toLocaleDateString('en-UK', {
          //   month: '2-digit',
          //   day: '2-digit',
          //   year: 'numeric',
          // });

          // item.PurchaseDate = formattedDate;

          //FirstInspectionDate

          // const FirstinspectionDate = item.FirstInspectionDate;
          // const FirstinspectiondateObj = new Date(FirstinspectionDate);
          // const FirstinspectionformattedDate = FirstinspectiondateObj.toLocaleDateString('en-UK', {
          //   month: '2-digit',
          //   day: '2-digit',
          //   year: 'numeric',
          // });

          // item.FirstInspectionDate = FirstinspectionformattedDate;

          //LastInspectionDate

          // const LastinspectionDate = item.LastInspectionDate;
          // const LastinspectiondateObj = new Date(LastinspectionDate);
          // const LastinspectionformattedDate = LastinspectiondateObj.toLocaleDateString('en-UK', {
          //   month: '2-digit',
          //   day: '2-digit',
          //   year: 'numeric',
          // });

          // item.LastInspectionDate = LastinspectionformattedDate;

          //NextInspectionDate

          // const NextinspectionDate = item.NextInspectionDate;
          // const NextinspectiondateObj = new Date(NextinspectionDate);
          // const NextinspectionformattedDate = NextinspectiondateObj.toLocaleDateString('en-UK', {
          //   month: '2-digit',
          //   day: '2-digit',
          //   year: 'numeric',
          // });

          // item.NextInspectionDate = NextinspectionformattedDate;

          return { ...item, id: item.EquipmentId };
        });
        if (latestPage === 0) {
          setFilerEquipData(receivedData);
        } else {
          setFilerEquipData([...filterEquipData, ...receivedData]);
        }

        // dispatch({
        //   type: latestPage === 0 ? getEquipmentData : addPaginationEquipmentData,
        //   payload: JSON.parse(response.data).map((item, i) => {
        //     const PurchasedateString = item.PurchaseDate;
        //     const dateObj = new Date(PurchasedateString);
        //     const formattedDate = dateObj.toLocaleDateString('en-UK', {
        //       month: '2-digit',
        //       day: '2-digit',
        //       year: 'numeric',
        //     });

        //     item.PurchaseDate = formattedDate;

        //     //FirstInspectionDate

        //     const FirstinspectionDate = item.FirstInspectionDate;
        //     const FirstinspectiondateObj = new Date(FirstinspectionDate);
        //     const FirstinspectionformattedDate = FirstinspectiondateObj.toLocaleDateString('en-UK', {
        //       month: '2-digit',
        //       day: '2-digit',
        //       year: 'numeric',
        //     });

        //     item.FirstInspectionDate = FirstinspectionformattedDate;

        //     //LastInspectionDate

        //     const LastinspectionDate = item.LastInspectionDate;
        //     const LastinspectiondateObj = new Date(LastinspectionDate);
        //     const LastinspectionformattedDate = LastinspectiondateObj.toLocaleDateString('en-UK', {
        //       month: '2-digit',
        //       day: '2-digit',
        //       year: 'numeric',
        //     });

        //     item.LastInspectionDate = LastinspectionformattedDate;

        //     //NextInspectionDate

        //     const NextinspectionDate = item.NextInspectionDate;
        //     const NextinspectiondateObj = new Date(NextinspectionDate);
        //     const NextinspectionformattedDate = NextinspectiondateObj.toLocaleDateString('en-UK', {
        //       month: '2-digit',
        //       day: '2-digit',
        //       year: 'numeric',
        //     });

        //     item.NextInspectionDate = NextinspectionformattedDate;

        //     return { ...item, id: item.EquipmentId };
        //   }),
        // });

        setFilterloading(false);
      }
      setFilterloading(false);
    } catch (error) {
      setFilterloading(false);
      console.error(error);
    }
  };
  useEffect(() => {
    if (filterData?.value?.length < 3 || typeof filterData?.value?.length === 'undefined') {
      setFilerEquipData(null);
      setFilterPaginationModel({ ...filterPaginationModel, page: 0 });
      setLatestPage(0);
    }
  }, [filterData]);

  useEffect(() => {
    if (latestPage > 0) {
      fetchFilterEquipData();
    }
  }, [latestPage]);

  useEffect(() => {
    if (filterPaginationModel.page > latestPage) {
      setLatestPage(latestPage + 1);
    }
  }, [filterPaginationModel]);
  return {
    equipmentDatas,
    fetchFilterEquipData,
    filterPaginationModel,
    setFilterPaginationModel,
    filterLoading,
    searchText,
    setSearchText,
    allEquipmentDatas,
    filterArray,
    setFilterArray,
    filterEquipData,
  };
};
