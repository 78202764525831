import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderData, getSupplierData } from 'src/redux/purchaseOrderSlice';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useFetchOrderData = () => {
  const purchaseOrderData = useSelector((state) => state.purchaseOrder.orderData);
  const supplierListData = useSelector((state) => state.purchaseOrder.supplierList);

  const dispatch = useDispatch();

  const fetchOrderData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/PurchaseOrder/GetPurchaseOrderDataList`);
      if (response.status === 200) {
        dispatch({
          type: getOrderData,
          payload: JSON.parse(response.data).map((item, i) => {
            const dateString = item.PODate;
            const dateObj = new Date(dateString);
            const formattedDate = dateObj.toLocaleDateString('en-UK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
            item.PODate = formattedDate;
            return { ...item, id: item.POId };
          }),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetSupplierData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/PurchaseOrder/GetSupplierList`);
      if (response.status === 200) {
        dispatch({
          type: getSupplierData,
          payload: JSON.parse(response.data),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (purchaseOrderData === null) {
      fetchOrderData();
    }
  }, [purchaseOrderData]);

  return { fetchOrderData, purchaseOrderData, supplierListData, GetSupplierData };
};
