import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import DataTable from 'src/components/datatable/DataTable';
import { useEffect } from 'react';
import DeleteBox from './DeleteBox';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import EditPurchaseModal from './EditPurchaseModal';
import { useFetchOrderData } from './useFetchOrderData';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import dayjs from 'dayjs';
import { CustomToolBarButton } from '../manifest/CustomToolBarButton';
import { toast } from 'react-toastify';

const CatArr = ['Cat1', 'Cat2', 'Cat3'];

const PurchaseOrder = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { fetchOrderData, purchaseOrderData, supplierListData, GetSupplierData } = useFetchOrderData();
  const { paginationModel, setPaginationModel } = useDefaultPagination();
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    if (supplierListData === null) {
      GetSupplierData();
    }
  }, [supplierListData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
      fetchOrderData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownloadOrder = async (row) => {
    if (!row) {
      toast.error(`Please select row`);
      return;
    }
    try {
      const response = await axios.get(
        `${ApiUrl2}api/PurchaseOrder/DownloadPurchaseOrderData?PurchaseOrderId=${row.POId}`,
        {
          responseType: 'blob',
        }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${row.PONumber}.xlsx`; 
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading NCR Excel file:', error);
    }
  };

  const actions = ['Download', 'Delete'];
  const handleModalAction = (actionType) => {
    switch (actionType) {
      case 'Delete':
        handleDeleteRow(editData);
        break;
      case 'Download':
        handleDownloadOrder(editData);
        break;
      default:
        break;
    }
  };
  const handleModalOpen = (data) => {
    setEditData(data);
  };

  const columns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: (params) => {
        const rowIndex = params.id;
        return (
          <input
            type="checkbox"
            id={rowIndex}
            disabled={(params.row.Status=='Deleted')? true : false}
            checked={editData?.POId === params?.row?.POId &&  params.row.Status!='Deleted' }
            onChange={() => handleModalOpen(editData?.POId === params?.row?.POId ? null :params.row)}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'Purchase Order No.',
      width: 180,
      sortable: true,
      renderCell: (params) => (
        // (params.row.Status=='Deleted' ) ?
        // <div >
        //   {params.row.PONumber}
        // </div>
        // :
        <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleEditRow(params.row)}>
          {params.row.PONumber}
        </div>
      ),
      valueGetter: (params) => `${params.row.PONumber || ''}`,
    },
    {
      field: 'date',
      headerName: 'Purchase Order Date',
      width: 210,
      sortable: true,
      valueGetter: (params) => dayjs(params.row.PODate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1Parts = v1.split('-').map(part => parseInt(part, 10));
        const date2Parts = v2.split('-').map(part => parseInt(part, 10));
    
        if (date1Parts[2] !== date2Parts[2]) {
          return date1Parts[2] - date2Parts[2];
        }
      
        if (date1Parts[1] !== date2Parts[1]) {
          return date1Parts[1] - date2Parts[1];
        }
        
        return date1Parts[0] - date2Parts[0];
      },
    },

    {
      field: 'SupplierName',
      headerName: ' Supplier Name ',
      width: 270,
      sortable: true,
      valueGetter: (params) => `${params.row.SupplierName || ''} `,
    },
    {
      field: 'Status',
      headerName: ' Status',
      width: 220,
      sortable: true,
      valueGetter: (params) => `${params.row.Status || ''} `,
    },
  ];

  const handleEditRow = (row) => {
    if (row) {
      setOpen(true);
      setEditData(row);
    } else {
      toast.error(`Please select row`);
    }
  };

  const handleDeleteRow = (row) => {
    if (row?.POId) {
      setDeleteOpen(true);
      setDeleteId(row.POId);
    } else {
      toast.error(`Please select row`);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
        <Typography variant="h4" className="mx-2 mx-xl-0 mb-3">
          Purchase Order
        </Typography>
        <div className="d-flex align-items-center flex-wrap" style={{ gap: '10px' }}>
          <PrimaryButton
            title="Add New Purchase Order"
            size="large"
            classNames="mx-2 mx-xl-0 mb-3"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>

      <DataTable
       tableName='Purchase_Orders'
        columns={columns}
        rows={filteredRows.length > 0 ? filteredRows : purchaseOrderData || []}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        renderEmptyCell={() => <h1>No data Found</h1>}
        toolbarNotRequired
        filterMode="client"
        CutomToolBarButton={<CustomToolBarButton actions={actions} handleModalAction={handleModalAction} />}
      />

      <EditPurchaseModal
        open={open}
        setOpen={setOpen}
        CatArr={CatArr}
        editData={editData}
        setEditData={setEditData}
        supplierListData={supplierListData}
      />
      <DeleteBox open={deleteOpen} setOpen={setDeleteOpen} deleteId={deleteId} setDeleteId={setDeleteId} />
    </>
  );
};

export default PurchaseOrder;
