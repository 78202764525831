import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderData: null,
  supplierList: null,
  // availableBag: null,
  // savedBag: null,
  // availableEquipment: null,
};

export const purchaseOrderSlice = createSlice({
  name: 'bag',
  initialState,
  reducers: {
    getOrderData: (state, action) => {
      state.orderData = action.payload;
    },
    addOrder: (state, action) => {
      const newData = { ...action.payload, id: action.payload.POId };
      state.orderData = [newData, ...state.orderData];
    },
    addUpdatedOrder: (state, action) => {
      const updatedVal = action.payload;
      const newOrderData = [...state.orderData];
      const element = newOrderData.findIndex((elem) => elem.POId === updatedVal.POId);
      newOrderData[element] = { ...updatedVal, id: newOrderData[element].POId };
      state.orderData = newOrderData;
    },
    deleteOrder: (state, action) => {
      const newOrderData = [...state.orderData];
      const element= newOrderData.findIndex((item) => item.POId === action.payload);
      newOrderData[element] = { Status: "Deleted", ...newOrderData[element] , Status: "Deleted"};
      state.orderData = newOrderData;
    },
    getSupplierData: (state, action) => {
      state.supplierList = action.payload;
    },
    // getAvailableBagData: (state, action) => {
    //   state.availableBag = action.payload;
    // },

    // updateAvaialbleBag: (state, action) => {
    //   let updatedBag = [...state.availableBag];
    //   const findInd = updatedBag.find((item) => item.BagId === action.payload.BagId);
    //   console.log(findInd);
    //   updatedBag[findInd] = {
    //     ...updatedBag[findInd],
    //     status: 'Bag',
    //   };

    //   state.availableBag = updatedBag;
    // },
    // addSavedBag: (state, action) => {
    //   state.savedBag = action.payload;
    // },
  },
});

export const { getOrderData, deleteOrder, addUpdatedOrder, addOrder, supplierList, getSupplierData } =
  purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
