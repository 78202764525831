import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { InputField1 } from 'src/components/inputFields/InputField';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import * as Validator from 'validatorjs';
import { useDispatch, useSelector } from 'react-redux';
import { addBagData, addNextBag, addUpdatedBag } from 'src/redux/bagSlice';
import { getNextBagNumber } from 'src/utils/formatNumber';

export default function EditBagModal({ open, setOpen, editData, setEditData, fetchBagData, bagIdData }) {
  const nextBag = useSelector((state) => state.bag.nextBag);
  const [name, setName] = useState(nextBag);
  const [description, setDescription] = useState('');
  const [customOption, setCustomOption] = useState('');
  const dispatch = useDispatch();

  console.log("edit", editData)

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
    setName(editData !== null ? '' : nextBag);
    setDescription('');
  };

  const descriptions = [
    "Lyon Sack 55L Yellow SKN",
    "Lyon Sack 55L RESO SKN",
  ];

  const allDescriptions = customOption && !descriptions.includes(customOption) 
    ? [customOption, ...descriptions] 
    : descriptions;


  useEffect(() => {
    if (bagIdData !== null) {
      setName(editData !== null ? bagIdData?.BagName : nextBag);
      setDescription(bagIdData?.BagDescription);
    } else {
      setName(nextBag);
    }
  }, [bagIdData, nextBag]);

  const handleFormSubmit = async () => {
    const formData = {
      BagName: name,
      BagDescription: description,
      // Status: status,
    };

    const checkdata = {
      // BagName: 'required',
      BagDescription: 'required',
    };

    const validation = new Validator(formData, checkdata);

    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((errorArray) => {
        errorArray.map((error) => {
          if (error.includes('BagName')) {
            toast.error(`Bag Name is required. Please enter a value.`);
          } else if (error.includes('BagDescription')) {
            toast.error(`Bag Description is required. Please enter a value.`);
          } else {
            toast.error(`${error}`);
          }
        });
      });
    } else {
      try {
        if (typeof editData?.BagId !== 'undefined') {
          formData.BagId = editData.BagId;
        }
        const response = await axios.post(`${ApiUrl2}api/bag/InsertSingleBagData`, formData);

        if (response.status === 200) {
          toast.success(`Bag ${editData !== null ? 'Edit' : 'Added'} Successfully`);
          fetchBagData();
          if (editData !== null) {
          } else {
            dispatch({
              type: addNextBag,
              payload: getNextBagNumber(nextBag),
            });
          }
        }
        handleClose();
      } catch (error) {
        console.error(error);
        toast.error('Error adding bag');
      }
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h4">{editData !== null ? 'Edit' : 'Add New'} Bag</Typography>
          <InputField1
            id="Name"
            name="Name"
            fullWidth
            autoComplete={'on'}
            label="SKN Number"
            value={name}
            margin="normal"
            disabled={true}
          />
          <FormControl fullWidth>
            <Autocomplete
              id="Description"
              name="Description"
              value={description}
              onChange={(e, newValue) => setDescription(newValue)}
              onInputChange={(e, newInputValue) => setCustomOption(newInputValue)}
              options={allDescriptions}
              disabled={editData ?true : false}
              getOptionLabel={(option) => option || ''}
              renderInput={(params) => <TextField {...params} placeholder="Select Description" variant="outlined" />}
              isOptionEqualToValue={(option, value) => option === value}
              sx={{ p: 0 }}
            />
          </FormControl>
          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleFormSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
