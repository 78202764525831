import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { useDispatch } from 'react-redux';
import { deleteBag } from 'src/redux/bagSlice';
import { deleteOrder } from 'src/redux/purchaseOrderSlice';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteBox({ open, setOpen, deleteId, setDeleteId }) {
  // console.log(deleteId);
  //   const [open, setOpen] = React.useState(false);
  //   console.log(deleteId);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const DeleteEquipment = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/PurchaseOrder/DeletePurchaseOrderData?PurchaseOrderId=${deleteId}`);
  
      if (response.status === 200) {
        dispatch({ type: deleteOrder, payload: deleteId });
        setDeleteId(null);
        handleClose();
        toast.success(`PO Deleted Successfully`);
      } else if (response.status === 400) {
        toast.error(response?.data?.Message || 'Bad Request');
      } else {
        toast.error('Unexpected error occurred');
      }
    } catch (error) {
      // Handle errors from the request
      console.error('Error deleting purchase order:', error.response.data.Message);
      toast.error(error.response.data.Message);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Typography variant="h4">Are you sure want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={DeleteEquipment} title="Delete" />
          <SecondaryButton onClick={handleClose} title="Cancel" />
        </DialogActions>
      </Dialog>
    </>
  );
}
