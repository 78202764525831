
import { Grid, Container, Typography } from '@mui/material';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import LocationTable from './LocationTable';
import StatusTable from './StatusTable';
import ClientTable from './ClientTable';

// ----------------------------------------------------------------------

export default function LocationStatusPage() {
  // const theme = useTheme();
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log('in location page')
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" className="mx-2 mx-xl-0 mb-4" >
          Location, Client, Status
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={4}>
           <LocationTable />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
          <ClientTable />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
          <StatusTable />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
