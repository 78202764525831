import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import { ApiUrl } from './ApiUrl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from 'src/redux/adminSlice';

export const useRememberMe = () => {
  const CheckRememberme = () => {
    const admin = useSelector((state) => state.admin.admin);
    const isRememberME = useSelector((state) => state.admin.isRememberMe);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
      const getUserData = async () => {
        if (admin === null) {
          if (isRememberME !== null) {
            const { email, password } = isRememberME;
            try {
              const response = await axios.post(`${ApiUrl}admin/signin`, { email, password });
              if (response.status === 200) {
                toast.success('Login Success');
                navigate('/dashboard', { replace: true });
                dispatch({ type: login, payload: response.data });
              }
            } catch (e) {
              toast.error(e.response.data.message);
            }
          }
        }
      };

      getUserData();
    }, [admin]); // eslint-disable-line react-hooks/exhaustive-deps
  };

  return { CheckRememberme };
};
