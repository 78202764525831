import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentCategoryData } from 'src/redux/equipmentCategorySlice';

import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useFetchCategoryData = () => {
  const dispatch = useDispatch();
  const [supplierListData, setSupplierListData] = useState(null);
  const [equipmentSingleData, setEquipmentSingleData] = useState();
  const [equipmentCategoryDatas, setEquipmentCategoryDatas] = useState(null);

  const fetchEquipCatData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/EquipmentCategory/GetEquipmentCategoryData`);
      if (response.status === 200) {
        const result = JSON.parse(response.data).map((item, i) => {
          return { ...item, id: i };
        });
        setEquipmentCategoryDatas(result);
        dispatch({
          type:   getEquipmentCategoryData,
          payload: result,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetSupplierData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/PurchaseOrder/GetSupplierList`);
      if (response.status === 200) {
        // let parsedSuppliers=(JSON.parse(response.data))
        // const data ={
        //   SupplierId:parsedSuppliers?.SupplierId,
        //   SupplierName: parsedSuppliers?.SupplierName

        //   }
        setSupplierListData(JSON.parse(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const GetEquipmentCategorySingleData = async (equipmentCategoryId, supplierCodeDescriptionId) => {
    try {
      const response = await axios.get(
        `${ApiUrl2}api/EquipmentCategory/GetEquipmentCtegoryDataForEdit?equipmentCategoryId=${equipmentCategoryId}&supplierCodeDescriptionId=${supplierCodeDescriptionId}`
      );
      if (response.status === 200) {
        setEquipmentSingleData(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    fetchEquipCatData,
    equipmentCategoryDatas,
    GetSupplierData,
    supplierListData,
    GetEquipmentCategorySingleData,
    equipmentSingleData,
  };
};
