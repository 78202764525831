import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  equipmentData: [],
  updatedData: [],
  savedEquipment: [],
  allEqupmentData: null,
  allEqupmentCategoryData:null,
  statusData:[],
  allConformityList:[]
};

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    getEquipmentData: (state, action) => {
      // console.log('run1');
      state.equipmentData = action.payload;
    },
    getAllEquipmentData: (state, action) => {
      // console.log('run1');
      state.allEqupmentData = action.payload;
    },
    getAllEquipmentCategoryData: (state, action) => {
      // console.log('run1');
      state.allEqupmentCategoryData = action.payload;
    },
    getAllConformity: (state, action) => {
      state.allConformityList = action.payload;
    },
    getStatusData: (state, action) => {
      // console.log('run1');
      state.statusData = action.payload;
    },
    addPaginationEquipmentData: (state, action) => {
      // console.log('run2');
      const newequipmentData = [...state.equipmentData];
      state.equipmentData = [...newequipmentData.slice(0, newequipmentData.length - 1), ...action.payload];
    },
    addEquipmentData: (state, action) => {
      const newData = { ...action.payload, id: state.equipmentData.length };
      state.equipmentData = [newData, ...state.equipmentData];
    },
    deleteEquipment: (state, action) => {
      state.equipmentData = state.equipmentData.filter((item) => item.EquipmentId !== action.payload);
    },
    deleteOrder: (state, action) => {
      const newEquipmentData = [...state.equipmentData];
      const element= newEquipmentData.findIndex((item) => item.EquipmentId === action.payload);
      newEquipmentData[element] = { ...newEquipmentData[element] , Status: "Deleted"};
      state.equipmentData = newEquipmentData;
    },
    addUpdatedEquipment: (state, action) => {
      const updatedVal = action.payload;
      const newequipmentData = [...state.equipmentData];
      const element = newequipmentData.findIndex((elem) => elem.EquipmentId === updatedVal.EquipmentId);
      newequipmentData[element] = { ...updatedVal, id: newequipmentData[element].id };
      state.equipmentData = [...newequipmentData];
    },
    getAvailableEquipmentData: (state, action) => {
      state.availableEquipment = action.payload;
    },
    addSavedEquipment: (state, action) => {
      state.savedEquipment = action.payload;
    },
    updateSavedEquipment: (state, action) => {
      const id = action.payload.EquipmentSknNumber;
      const findExist = state.savedEquipment.find((item) => item.EquipmentSknNumber === id);

      if (findExist) {
        state.savedEquipment = state.savedEquipment.filter((item) => item.EquipmentSknNumber !== id);
      } else {
        state.savedEquipment = [...state.savedEquipment, action.payload];
      }
    },
    updateEquipmentAvaialable: (state, action) => {
      const newequipmentData = [...state.availableEquipment];
      const findIndex = action.payload;

      if (typeof newequipmentData[findIndex] !== 'undefined') {
        newequipmentData[findIndex] = {
          ...newequipmentData[findIndex],
          status: newequipmentData[findIndex].status === 'Equipment' ? 'Manifest' : 'Equipment',
        };
      }
      state.availableEquipment = newequipmentData;
    },
  },
});

export const {
  getAvailableEquipmentData,
  getEquipmentData,
  getAllEquipmentCategoryData,
  addEquipmentData,
  deleteEquipment,
  addUpdatedEquipment,
  addSavedEquipment,
  addPaginationEquipmentData,
  updateEquipmentAvaialable,
  updateSavedEquipment,
  getAllEquipmentData,
  getStatusData,
  getAllConformity
} = equipmentSlice.actions;

export default equipmentSlice.reducer;
