import React from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';
import { DeleteButton, EditButton } from 'src/components/buttons/MyButton';

const Quote = () => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Typography variant="h4" sx={{ mb: 5 }}>
        Quote
      </Typography>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Quote</th>
            <th scope="col">{checked ? 'Active' : 'Deactive'}</th>
            <th scope="col">Edit/Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="pt-3">Mark</td>
            <td>
              <FormControlLabel
                control={
                  <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                }
              />
            </td>
            <td>
              <EditButton />
              <DeleteButton />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Quote;
