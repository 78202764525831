import CryptoJS from 'crypto-js';

export const StoredVariables = {
  adminData:
    '$2y$12$Sfgfr5xn80lXcy7Trr1Ix.$2y$12$Sfgfr5xn80lXcy7T0OhEzOU13GDIWwZDeTC/$2y$12$Sfgfr5xn80lXcy7Trr1Ix.8EZEd2/gGfp...',
  isRememberMe: 'asdsadhsauhdsahfuhd9fhewq%$fdfpjdgjjerjgogf',
};

//SESSION STORAGE
export const SetSession = (key, data) =>
  sessionStorage.setItem(
    key,
    CryptoJS.AES.encrypt(
      Array.isArray(data) || typeof data === 'object' ? JSON.stringify(data) : data,
      'secret key 123'
    ).toString()
  );

export const GetSession = (key) => {
  var sessionData = sessionStorage.getItem(key);
  var bytes = CryptoJS.AES.decrypt(sessionData, 'secret key 123');

  try {
    var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return originalText;
  } catch {
    var originalText2 = bytes.toString(CryptoJS.enc.Utf8);
    return originalText2;
  }
};

//LOCAL STORAGE

export const SetLocalSession = (key, data) =>
  localStorage.setItem(
    key,
    CryptoJS.AES.encrypt(
      Array.isArray(data) || typeof data === 'object' ? JSON.stringify(data) : data,
      'secret key 123'
    ).toString()
  );

export const GetLocalSession = (key) => {
  var sessionData = localStorage.getItem(key);
  var bytes = CryptoJS.AES.decrypt(sessionData, 'secret key 123');

  try {
    var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return originalText;
  } catch {
    var originalText2 = bytes.toString(CryptoJS.enc.Utf8);
    return originalText2;
  }
};

export const RemoveLocalSession = (key) => localStorage.removeItem(key);
export const RemoveSession = (key) => sessionStorage.removeItem(key);
