import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { ColorButton, PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { toast } from 'react-toastify';
import Loader from 'src/components/loader/Loader';

export default function UploadAuditModal({ open, setOpen, editData }) {
  const [excelFiles, setExcelFiles] = useState([]);
  const handleClose = () => {
    setOpen(false);
    setExcelFiles([]);
  };
  const [isLoading, setIsLoading] = useState(false)
  const handleFileUpload = (event) => {
    const files = event.target.files;
    const allowedSize = 5 * 1024 * 1024;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > allowedSize) {
        toast.error(`File name "${file.name}" exceeds the maximum allowed size of 5MB.`);
      } else {
        setExcelFiles([...excelFiles, file]);
      }
    }
  };


  const handleRemoveFile = (index) => {
    const updatedFiles = excelFiles.filter((_, i) => i !== index);
    setExcelFiles(updatedFiles);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    excelFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    if (excelFiles.length > 0) {
      setIsLoading(true)
      try {
        const response = await axios.post(
          `${ApiUrl2}api/Manifest/UploadManifestAuditFiles?manifestId=${editData?.ManifestId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', // Set the content type for file uploads
            },
          }
        );

        if (response.status === 200) {
          toast.success(response.data);
          setExcelFiles([]);
          setOpen(false);
          setIsLoading(false)
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.Message);
        }
        console.error(error);
        setIsLoading(false)
      }
    } else {
      toast.error("Please Upload the file first.");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isLoading ?
          <Loader />
          :
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h4">
              Add New PDF
            </Typography>

            <div
              className="my-4 d-flex  flex-column align-items-center  align-items-center justify-content-center "
              style={{ border: '2px solid var(--500)', minHeight: '150px', borderRadius: '8px' }}
            >
              {excelFiles.length > 0 ? (
                <ul style={{ listStyleType: 'none' }}>
                  {excelFiles.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <SecondaryButton
                        classNames="ms-2 mt-2"
                        title="Remove"
                        type="button"
                        onClick={() => handleRemoveFile(index)}
                      />
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <Typography className="my-1" color={"red"} variant="body">
                    You can upload file up to 5MB only!
                  </Typography>
                  <ColorButton variant="contained" component="label">
                    <input hidden accept=".pdf" multiple={false} type="file" onChange={handleFileUpload} />
                    Upload
                  </ColorButton>
                </>
              )}
            </div>

            <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
              <PrimaryButton title="Submit" onClick={handleSubmit} />
              <SecondaryButton title="Cancel" onClick={handleClose} />
            </div>
          </Box>
        }
      </Modal>
    </div>
  );
}
