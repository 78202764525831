import axios from 'axios';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSavedBag, getAvailableBagData } from 'src/redux/bagSlice';
import { addSavedEquipment, getAvailableEquipmentData} from 'src/redux/equipmentSlice';
import { getClientData, getLocationData, getmanifestData, setLastInspectionDate, setNextInspectionDate } from 'src/redux/manifestSlice';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export const useGetAvailableData = () => {
  const [manifestName, setManifestName] = useState('SKN000');
  const [location, setLocation] = useState('');
  const [jobNo, setJobNo] = useState('');
  const [client, setClient] = useState('');
  const [startDate, setStartDate] = useState(dayjs(''));
  const [endDate, setEndDate] = useState(dayjs(''));
  const [editData, setEditData] = useState(null);
  const [comments, setComments] = useState('');
  const [availableManifestData, setAvailableManifestData] = useState('');

  const availableBags = useSelector((state) => state.bag.availableBag);
  const availableEquipment = useSelector((state) => state.equipment.availableEquipment);

  // console.log(availableEquipment);
  const dispatch = useDispatch();

  const fetchAvaialbleBags = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/Manifest/GetAvailableBags`);
      if (response.status === 200) {
        // console.log(response.data);
        const addStatus = JSON.parse(response.data).map((data, i) => ({
          ...data,
          status: 'Bag',
          id: i,
          BagName: data.BagName,
        }));

        dispatch({ type: getAvailableBagData, payload: addStatus });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getSingleManifestData = async (manifestId) => {
    const response = await axios.get(`${ApiUrl2}api/Manifest/GetSingleManifestDetailData?ManifestId={${manifestId}}`);
    if (response.status === 200) {
      const editData = JSON.parse(response.data || '');
      setManifestName(editData.ManifestName || '');
      setLocation(editData.Location || '');
      setJobNo(editData.JobNo || '');
      setStartDate(dayjs(editData.LastInspectionDate) || '');
      setEndDate(dayjs(editData.NextInspectionDate) || '');
      setClient(editData.Client || '');
      setComments(editData.Comments || '');
      setEditData(editData);
      dispatch({
        type: setLastInspectionDate,
        payload: dayjs(editData.LastInspectionDate),
      });
      dispatch({
        type: setNextInspectionDate,
        payload: dayjs(editData.NextInspectionDate),
      });
      dispatch({
        type: addSavedBag,
        payload: editData?.BagMappings?.map((data, i) => ({ ...data, status: 'Manifest', id: i+1})),
      });
      dispatch({
        type: addSavedEquipment,
        payload: editData?.EquipmentMpappingModels?.map((data, i) => ({ ...data, status: 'Manifest', id: i+1 })),
      });
    }
  };
  const getMoreAvailabledataManifestData = async (manifestId) => {
    try {
      const response = await axios.get(`${ApiUrl2}api/Manifest/GetEquipmentsList?manifestId=${manifestId}`);
      if (response.status === 200) {
        setAvailableManifestData(JSON.parse(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchEquipData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/Manifest/GetManifestDataList`);
      if (response.status === 200) {
        dispatch({
          type: getmanifestData,
          payload: JSON.parse(response.data).map((item, i) => {
            const startdateString = item.LastInspectionDate;
            const closedateString = item.NextInspectionDate;
            const startdateObj = new Date(startdateString);
            const formattedStartDate = startdateObj.toLocaleDateString('en-UK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
            const closedateObj = new Date(closedateString);
            const formattedCloseDate = closedateObj.toLocaleDateString('en-UK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });

            item.LastInspectionDate = item.LastInspectionDate === null ? null : formattedStartDate;
            item.NextInspectionDate = item.NextInspectionDate === null ? null : formattedCloseDate;
            return { ...item, id: i };
          }),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAvaialbleEquipments = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/Manifest/GetAvailableEquipment`);

      if (response.status === 200) {
        const addStatus = JSON.parse(response.data).map((data, i) => ({
          ...data,
          status: 'Equipment',
          id: i,
        }));

        dispatch({ type: getAvailableEquipmentData, payload: addStatus });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLocationList = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}/api/Manifest/GetSystemGenericList?listName=location`);
      if (response.status === 200) {
        dispatch({
          type: getLocationData,
          payload: JSON.parse(response.data),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getClientList = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}/api/Manifest/GetSystemGenericList?listName=Client`);
      if (response.status === 200) {
        dispatch({
          type:  getClientData,
          payload: JSON.parse(response.data),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };


  return {
    fetchEquipData,
    fetchAvaialbleEquipments,
    availableBags,
    fetchAvaialbleBags,
    getSingleManifestData,
    manifestName,
    setManifestName,
    location,
    setLocation,
    jobNo,
    setJobNo,
    client,
    setClient,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    editData,
    setEditData,
    availableEquipment,
    comments,
    setComments,
    getMoreAvailabledataManifestData,
    availableManifestData,
    getLocationList,
    getClientList 
  };
};

export const useGetAuditList = () => {
  const [auditData, setAuditData] = useState(null);
  const GetAuditDataList = async ({ editData }) => {
    console.log(editData.ManifestId);
    try {
      const response = await axios.get(`${ApiUrl2}api/Manifest/GetAuditDataList?manifestId={${editData.ManifestId}}`);
      // console.log(JSON.parse(response.data));
      if (response.status === 200) {
        setAuditData(
          JSON.parse(response.data).map((item, i) => {
            const audidFormatDate = item.AuditDate;
            const audidFormatDateObj = new Date(audidFormatDate);
            const formattedDate = audidFormatDateObj.toLocaleDateString('en-UK', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });

            item.AuditDate = formattedDate;
            return { ...item, id: i };
          })
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { GetAuditDataList, auditData };
};
