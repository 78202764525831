import * as React from 'react';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import {
  Box,
  FormControl,
  Typography,
  TextField,
  Autocomplete
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { PrimaryButton } from 'src/components/buttons/MyButton';
import DataTable from 'src/components/datatable/DataTable';
import { ApiUrl2 } from 'src/utils/ApiUrl';

export default function MoreAvailableEquipmentModal({ open, setOpen, type, manifestIdValue,  getSingleManifestData }) {
  const handleClose = () => setOpen(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });

  const [selectedReplacements, setSelectedReplacements] = React.useState({});

  const handleReplacementChange = (equipmentId, replacementId) => {
    const isDuplicate = Object.values(selectedReplacements).includes(replacementId);
    if (isDuplicate) {
      toast.error("Replacement already selected. Please choose another one.");
    } else {
      setSelectedReplacements({
        ...selectedReplacements,
        [equipmentId]: replacementId,
      });
    }
  };

  const getSelectedReplacements = () => {
    return Object.entries(selectedReplacements).map(([equipmentId, replacementId]) => ({
      "nonConformantEquipmentId": equipmentId,
      "acceptReplacementId": replacementId
    }));
  };

  const handleReplace = () => {
    const replacementData = getSelectedReplacements();
    const values = {
      "manifestId": manifestIdValue,
      "replacements": replacementData
    };

    const manifestRequirement = async (values) => {
      try {
        const response = await axios.post(`${ApiUrl2}/api/Manifest/ReplacedEquipment`, values);
        if (response.status === 200) {
          toast.success("Replaced equipment successfully.");
          getSingleManifestData(manifestIdValue);
        } else {
          toast.error("Error replacing equipment.");
        }
      } catch (error) {
        console.error("Error replacing equipment:", error);
        toast.error("Error replacing equipment.");
      }
    };

    manifestRequirement(values);
    handleClose();
  };

  const columns = [
    { field: 'NonConformantSknNumber', headerName: 'Equipment SknNumber', width: 220 },
    { field: 'SupplierDescription', headerName: 'Supplier Description', width: 260 },
    { field: 'EquipmentCategoryName', headerName: 'Equipment Category Name', width: 240 },
    {
      field: 'AvailableReplacements',
      headerName: 'Replacement',
      width: 240,
      renderCell: (params) => (
        <FormControl fullWidth>
          <Autocomplete
            value={params.row.AvailableReplacements.find(replacement => replacement.EquipmentId === selectedReplacements[params.row.NonConformantEquipmentId]) || null}
            onChange={(e, newValue) => handleReplacementChange(params.row.NonConformantEquipmentId, newValue ? newValue.EquipmentId : '')}
            options={params.row.AvailableReplacements}
            getOptionLabel={(option) => option.EquipmentSknNumber || ''}
            renderInput={(params) => <TextField {...params} placeholder="Select Replacement" variant="outlined" />}
            isOptionEqualToValue={(option, value) => option.EquipmentId === value.EquipmentId}
            sx={{ p: 0 }}
          />
        </FormControl>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h4" className="mb-3">
          Available Equipment
        </Typography>
        <div style={{ height: 350, width: '100%' }}>
          <DataTable
            tableName='Equipment'
            rows={type}
            columns={columns}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            renderEmptyCell={() => <h1>No data Found</h1>}
            toolbarNotRequired
            filterMode="client"
            height={350}
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <PrimaryButton title={`Replace`} onClick={handleReplace} />
        </div>
      </Box>
    </Modal>
  );
}
