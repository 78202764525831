// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('dashboard'),
  },

  {
    title: 'purchase order ',
    path: '/dashboard/purchaseOrder',
    icon: icon('purchase'),
  },

  {
    title: 'equipment ',
    path: '/dashboard/equipment',
    icon: icon('equipment'),
  },

  {
    title: 'bags',
    path: '/dashboard/bag',
    icon: icon('bag'),
  },
  {
    title: 'equipment Category',
    path: '/dashboard/equipmentCategory',
    icon: icon('e_category'),
  },
  {
    title: 'RopeKit List',
    path: '/dashboard/ropekitList',
    icon: icon('manifest'),
  },
  {
    title: 'create update RopeKit ',
    path: '/dashboard/ropekit',
    icon: icon('create'),
  },
  {
    title: 'Location/Client/Status ',
    path: '/dashboard/location',
    icon: icon('equipment'),
  },
  // {
  //   title: 'Supplier',
  //   path: '/dashboard/supplier',
  //   icon: icon('manifest'),
  // },

  // {
  //   title: 'home',
  //   path: '/dashboard/home',
  //   icon: icon('home'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;

// Equipment===>   , Job No ,  Days to Inspection , Inspection Status , Date to be removed from service ,NCR number (nullable)
