import DataTable from 'src/components/datatable/DataTable';
import { Heading2 } from 'src/components/headings/Heading';
import EditIcon from '@mui/icons-material/Edit';
import { Paper } from '@mui/material';
import { useFetchDashboardData } from './useGetDashboardData';
import { useEffect, useState } from 'react';
import EquipmentDetailModal from './EquipmentDetailModal';
import { useFetchCategoryData } from 'src/pages/equipment_category/usefetchEquipmentData';
import { useNavigate } from 'react-router-dom';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import dayjs from 'dayjs';

export default function NextWeekInspection() {
  const [open, setOpen] = useState(false);
  const { fetchNextWeekInspectionData, inspectionNextWeekDatas } = useFetchDashboardData();
  const [eqDetail, setEqDetail] = useState(null);
  const { equipmentCategoryDatas } = useFetchCategoryData();
  // const { paginationModel, setPaginationModel } = useDefaultPagination();
  const navigate = useNavigate();

  useEffect(() => {
      fetchNextWeekInspectionData();
  }, []);

  // State to manage filter model
  // const [filterModel, setFilterModel] = useState(null);

  const columns = [
    {
      field: 'name',
      headerName: 'RopeKit Name',
      filterable: true,
      width: 200,
      sortable: true,
      valueGetter: (params) => params.row.SKNNumber,
    },
    {
      field: 'Location',
      headerName: 'Location',
      width: 150,
      valueGetter: (params) => `${params.row.Location || ''} `,
      sortable: true,
    },
    {
      field: 'date',
      filterable: true,
      headerName: 'Date',
      width: 130,
      sortable: true,
      valueGetter: (params) => dayjs(params?.row?.NextInspectionDate, 'DD/MM/YYYY').format('DD-MM-YYYY'),
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        const date1Parts = v1.split('-').map(part => parseInt(part, 10));
        const date2Parts = v2.split('-').map(part => parseInt(part, 10));
    
        if (date1Parts[2] !== date2Parts[2]) {
          return date1Parts[2] - date2Parts[2];
        }
      
        if (date1Parts[1] !== date2Parts[1]) {
          return date1Parts[1] - date2Parts[1];
        }
        
        return date1Parts[0] - date2Parts[0];
      },
    },
    {
      field: 'view',
      headerName: 'View',
      align: 'right',
      headerAlign: 'right',
      disableExport: true,
      flex: 1,
      renderCell: (params) => {
        const rowIndex = params.id;
        return (
          <EditIcon
            id={`NWI-edit-${rowIndex}`}  
            sx={{ color: `var(--500)`, cursor: 'pointer' }}
            onClick={() => navigate(`/dashboard/ropekit/?id=${params.row.EquipmentId}`)}
          />
        );
      },
    }
  ];
  const customOptions = {
    exportButton: true,
    exportAllData: true,
    getExportFileBlob: () => {
      // Implement your custom export logic here
    },
    exportOptions: {
      columns: ['name', 'date'], // Specify the columns to export
      csvDelimiter: ';', // Customize CSV delimiter if needed
      fileName: 'export', // Customize file name if needed
    },
  };

  return (
    <>
      <Paper className="p-3" id="next_inspection_table">
        <div className="mt-4">
          <Heading2 title="Next Month Inspection Date" classNames="mb-4" />
          {inspectionNextWeekDatas !== null && (
            <DataTable
             tableName='Next_Week_Inspections'
              columns={columns}
              rows={inspectionNextWeekDatas}
              toolbarNotRequired
              filterMode="client"
              options={customOptions} // Pass custom options to the DataTable
              // paginationModel={paginationModel}
              // setPaginationModel={setPaginationModel}
              // filterModel={filterModel} // Pass filter model to the DataTable
              // onFilterModelChange={setFilterModel} // Handle filter model changes
            />
          )}
        </div>
      </Paper>
      <EquipmentDetailModal
        open={open}
        setOpen={setOpen}
        eqDetail={eqDetail}
        equipmentCategoryDatas={equipmentCategoryDatas}
        setEqDetail={setEqDetail}
      />
    </>
  );
}
