import { Switch, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
// import EditBagModal from './EditBagModal';
// import DeleteBox from './DeleteBox';
import { useEffect } from 'react';
import { useFetchBagData } from './useFetchBagData';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import { CustomToolBarButton } from '../manifest/CustomToolBarButton';
import { toast } from 'react-toastify';
import DataTable from 'src/components/datatable/DataTable';
import EditBagModal from './EditBagModal';
import DeleteBox from './DeleteBox';
import { useSelector } from 'react-redux';

const CatArr = ['Cat1', 'Cat2', 'Cat3'];

const Bag = () => {
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { bagDatas, fetchBagData, bagIdData, getBagDataUsingId} = useFetchBagData();
  const { paginationModel, setPaginationModel } = useDefaultPagination();
   const bagData =useSelector((state) => state.bag.bagData);
  const actions = ['Delete'];
  const handleModalAction = (actionType) => {
    switch (actionType) {
      case 'Delete':
        handleDeleteRow(editData);
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      field: 'select',
      headerName: 'Select',
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true ,
      renderCell: (params) => {
        const rowIndex = params.id;
        return (
          <input
            type="checkbox"
            id={rowIndex }
            checked={editData?.BagId === params?.row?.BagId}
            onChange={() => handleModalOpen(editData?.BagId === params?.row?.BagId ? null :params.row)}
          />
        );
      },
    },
    {
      field: 'name',
      headerName: 'SKN Number',
      width: 120,
      renderCell: (params) => (
        <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleEditRow(params.row)}>
          {params.row.BagName}
        </div>
      ),
      valueGetter: (params) => `${params.row.BagName || ''}`,
      sortable: true,
    },
    {
      field: 'Description',
      headerName: 'Description',
      width: 220,
      valueGetter: (params) => `${params.row.BagDescription || ''}`,
      sortable: true,
    },,
    {
      field: 'ManifestName',
      headerName: 'Ropekit Name',
      width: 220,
      valueGetter: (params) => `${params.row.ManifestName || ''} `,
      sortable: true,
    },
    {
      field: 'Location',
      headerName: 'Location',
      width: 160,
      valueGetter: (params) => `${params.row.Location || ''} `,
      sortable: true,
    },
    {
      field: 'Client',
      headerName: ' Client',
      width: 160,
      valueGetter: (params) => `${params.row.Client || ''} `,
      sortable: true,
    },
    {
      field: 'NCRNumber',
      headerName: 'NCR Number',
      width: 220,
      valueGetter: (params) => `${params.row.NCRNumber || ''} `,
      sortable: true,
    },
    {
      field: 'Status',
      headerName: 'Status',
      sortable: true,
      width: 140,
      valueGetter: (params) => {
        const status = params?.row?.Status;
    
        if (status === "IS" || status === "SP") {
          return status;
        }

        return status
          ?.split(" ")                   
          ?.map(word => 
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )                             
          ?.join(" ");
        }
      }
  ];
  const handleModalOpen = (data) => {
    setEditData(data);
  };
  const handleEditRow = (row) => {
    if (row) {
      setOpen(true);
      getBagDataUsingId(row.BagId);
      setEditData(row);
    } else {
      toast.error(`Please select row`);
    }
  };

  const handleDeleteRow = (row) => {
    if (row) {
      setDeleteOpen(true);
      setDeleteId(row.BagId);
    } else {
      toast.error(`Please select row`);
    }
  };

  useEffect(() => {
    // if ( bagData === null) {
      fetchBagData();
    // }
  }, []);

  useEffect(() => {
    if ( bagData === null) {
      fetchBagData();
    }
  }, [ bagData]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
        <Typography variant="h4" className="mx-2 mx-xl-0 mb-3">
          Bag
        </Typography>
        <div className="d-flex align-items-center flex-wrap" style={{ gap: '10px' }}>
          <PrimaryButton
            title="Add New Bag"
            size="large"
            classNames="mx-2 mx-xl-0 mb-3"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      { bagData !== null && (
        <DataTable
          tableName='bag'
          columns={columns}
          rows={ bagData || []}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          renderEmptyCell={() => <h1>No data Found</h1>}
          toolbarNotRequired
          filterMode="client"
          CutomToolBarButton={<CustomToolBarButton actions={actions} handleModalAction={handleModalAction} />}
        />
      )}
      <EditBagModal
        open={open}
        setOpen={setOpen}
        CatArr={CatArr}
        editData={editData}
        setEditData={setEditData}
        fetchBagData={fetchBagData}
        bagIdData={editData ? bagIdData : null}
      />
      <DeleteBox open={deleteOpen} setOpen={setDeleteOpen} deleteId={deleteId} setDeleteId={setDeleteId} />
    </>
  );
};

export default Bag;
