import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import BlogPage from './pages/BlogPage';
import LoginPage from './pages/loginPage/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import { useSelector } from 'react-redux';
import HomePage from './pages/home/HomePage';
import RegisterPage from './pages/registerPage/RegisterPage';
import ForgotPasswordPage from './pages/forgotPassword/ForgotPasswordPage';
import EquipmentCategory from './pages/equipment_category/EquipmentCategory';
import Equipments from './pages/equipments/Equipments';
import Manifest from './pages/manifest/Manifest';
import ManifestList from './pages/manifest/ManifestList';
import Bag from './pages/bag/Bag';
import PurchaseOrder from './pages/purchaseOrder/PurchaseOrder';
import LocationStatusPage from './pages/locationStatus/LocationStatusPage';
import SupplierPage from './pages/supplier/SupplierPage';
// ----------------------------------------------------------------------

export default function Router() {
  const admin = useSelector((state) => state.admin.admin);
  // console.log(admin);

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: admin === null ? <Navigate to="/login" /> : <DashboardLayout />,
      // element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'home', element: <HomePage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'equipmentCategory', element: <EquipmentCategory /> },
        { path: 'equipment', element: <Equipments /> },
        { path: 'bag', element: <Bag /> },
        { path: 'ropekit', element: <Manifest /> },
        { path: 'ropekitlist', element: <ManifestList /> },
        { path: 'location', element:  <LocationStatusPage /> },
        { path: 'purchaseOrder', element: <PurchaseOrder /> },
        { path: 'supplier', element : <SupplierPage />}
      ],
    },
    {
      path: 'login',
      element: admin === null ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      path: 'register',
      element: admin === null ? <RegisterPage /> : <Navigate to="/dashboard/app" />,
    },

    {
      path: 'forgotPassword',
      element: admin === null ? <ForgotPasswordPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
