import axios from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNextBag, getBagData } from 'src/redux/bagSlice';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { getNextBagNumber } from 'src/utils/formatNumber';

export const useFetchBagData = () => {
  const dispatch = useDispatch();

  const [bagIdData, setBagIdData] = useState(null);
  const [bagDatas, setBagDatas] = useState(null);

  const fetchBagData = async () => {
    try {
      const response = await axios.get(`${ApiUrl2}api/Bag/GetBagDataList`);
      if (response.status === 200) {
        const bagDatalist = JSON.parse(response.data).map((item, i) => {
          return { ...item, id: i };
        });
        setBagDatas(bagDatalist);
        dispatch({
          type:   getBagData,
          payload: bagDatalist ,
        });
        if (bagDatalist.length > 0) {
          dispatch({
            type:  addNextBag,
            payload: getNextBagNumber(bagDatalist[0].BagName) ,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getBagDataUsingId = async (id) => {
    try {
      const response = await axios.get(`${ApiUrl2}api/bag/GetSingleBagData?BagId=${id}`);
      if (response.status === 200) {
        setBagIdData(JSON.parse(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  return { fetchBagData, bagDatas, getBagDataUsingId, bagIdData };
};
