import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import './style.css';
import { PrimaryButton } from '../buttons/MyButton';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { GridToolbarColumnsButton } from '@mui/x-data-grid';
import { GridToolbarFilterButton } from '@mui/x-data-grid';
import { GridToolbarDensitySelector } from '@mui/x-data-grid';
import { GridToolbarExport } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

const DataTable = ({
  tableName,
  columns,
  rows,
  initialState,
  renderEmptyCell,
  paginationModel,
  setPaginationModel,
  backendFilter,
  setFilterArray,
  filterMode,
  CutomToolBarButton,
  isGenericPage=false,
  totalCountEqui,
  height=520
}) => {
  const columnsWithFilter = columns.map((column) => ({
    ...column,
    // filterable: true,
  }));

  const CustomNoRowsOverlay = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '5px',
        }}
      >
        No data available
      </div>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Box>
          <GridToolbarColumnsButton id="columns-button" name="columns-button" />
          {!isGenericPage && (
            <GridToolbarFilterButton id="filter-button" name="filter-button" />
          )}
          <GridToolbarDensitySelector id="density-button" name="density-button" />
          <GridToolbarExport
            id="export-button"
            name="export-button"
            csvOptions={{
              fileName: tableName,
            }}
          />
        </Box>
        {CutomToolBarButton}
      </GridToolbarContainer>
    );
  }
  const handleFilterModelChange = (e) => {
    if (backendFilter === 'Equipment') {
      setFilterArray(e);
    }
  };
  
  return (
    <div style={{ height: height }}>
      <DataGrid
        renderEmptyCell={renderEmptyCell && renderEmptyCell}
        columns={columnsWithFilter}
        rows={rows}
        initialState={initialState}
        components={{
          // Toolbar: GridToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        rowCount={totalCountEqui}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onFilterModelChange={handleFilterModelChange}
        filterMode={filterMode}
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

export default DataTable;
