import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tabCount: 0,
};

export const tabSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.tabCount = parseInt(state.tabCount) + 1;
    },
    decrement: (state) => {
      state.tabCount = parseInt(state.tabCount) - 1;
    },
    changeTabVal: (state, action) => {
      state.tabCount = action.payload;
    },
    valToZero: (state) => {
      state.tabCount = 0;
    },
  },
});

export const { increment, decrement, changeTabVal, valToZero } = tabSlice.actions;

export default tabSlice.reducer;
