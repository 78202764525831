import { createSlice } from '@reduxjs/toolkit';
import {
  GetLocalSession,
  GetSession,
  RemoveLocalSession,
  RemoveSession,
  SetSession,
  StoredVariables,
} from 'src/utils/SecureStorage';

const initialState = {
  admin: sessionStorage.getItem(StoredVariables?.adminData) ? GetSession(StoredVariables.adminData) : null,
  isRememberMe: localStorage.getItem(StoredVariables?.isRememberMe)
    ? GetLocalSession(StoredVariables.isRememberMe)
    : null,
};

export const adminSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    logout: (state) => {
      RemoveLocalSession(StoredVariables.isRememberMe);
      RemoveSession(StoredVariables.adminData);
      state.admin = null;
      state.isRememberMe = null;
    },
    login: (state, action) => {
      SetSession(StoredVariables.adminData, action.payload);
      state.admin = action.payload;
    },
    reset: (state) => {
      state.count = 0;
    },
    incrementByAmount: (state, action) => {
      state.count += action.payload;
    },
  },
});

export const { logout, login, reset, incrementByAmount } = adminSlice.actions;

export default adminSlice.reducer;
