import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { InputField1 } from 'src/components/inputFields/InputField';
import axios from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { useDispatch } from 'react-redux';
import Validator from 'validatorjs';
import { addClientData, updateClientData } from 'src/redux/locationSlice';


export default function EditClient({
  open, setOpen, editData, actionType, setEditData
}) {

  const handleClose = () => {
    setOpen(false);
    setEditData(null);
  };

  const [client, setClient] = React.useState('');
  const [id, setId] = React.useState(null);
  const dispatch = useDispatch();

  const handleFormSubmit = async () => {
    const props = {
      values: {
        ListName: "Client",
        OldListValue: editData === undefined ? "" : editData?.client,
        NewListValue: client,
      },
      type: actionType,
      idValue: id,
    };

    const validation = new Validator({ client }, { client: 'required' });
    if (validation.fails()) {
      toast.error(`Client is required. Please enter a value.`);
      return;
    }


    const addEditGenericList = async ({ values, type, idValue }) => {
      try {
        const response = await axios.post(`${ApiUrl2}api/Manifest/SaveSystemGenericList`, values);
        if (response.status === 200) {
          if (type === "add") {
            dispatch({
              type: addClientData,
              payload: values?.NewListValue,
            });
          } else {
            dispatch({
              type: updateClientData,
              payload: { id: idValue, newData: values?.NewListValue },
            });
          }
        }
        toast.success(`Client ${type === "add" ? 'Added' : 'Edit'} Successfully`);
      } catch (error) {
        console.error(error);
      }
    };

    addEditGenericList(props);
    handleClose()
  };

  useEffect(() => {
    if (editData) {
      setId(editData.id)
      setClient(editData.client);
    } else {
      setClient();
      setId(null)
    }
  }, [editData]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h4">{editData !== null ? 'Edit' : 'Add New'} Client</Typography>
          <InputField1
            id="Client"
            name="Client"
            fullWidth
            label="Client"
            autoComplete={'on'} 
            value={client}
            onChange={(e) => setClient(e.target.value)}
            margin="normal" />
          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleFormSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
