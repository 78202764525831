import { Button, Stack } from '@mui/material';
import React from 'react';
import DownloadIcon from '@mui/icons-material/Download'; // Import icons
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';

export const CustomToolBarButton = ({ actions, handleModalAction }) => {

  const renderActionWithIcon = (action) => {
    if (action.toLowerCase().includes('download')) {
      return <><DownloadIcon /> {action.replace(/download/i, '')}</>; // Replace "Download" with icon
    }
    if (action.toLowerCase().includes('upload')) {
      return <><UploadIcon /> {action.replace(/upload/i, '')}</>; // Replace "Upload" with icon
    }
    if (action.toLowerCase().includes('delete')) {
      return <><DeleteIcon /> {action.replace(/delete/i, '')}</>; // Replace "Delete" with icon
    }
    return action; 
  };

  return (
    <Stack direction="row" spacing={1}>
      {actions.map((action, index) => (
        <Button
          id={action}
          name={action}
          key={index}
          variant="text"
          color="primary"
          onClick={() => handleModalAction(action)}
          startIcon={null} 
        >
          {renderActionWithIcon(action)}
        </Button>
      ))}
    </Stack>
  );
};
