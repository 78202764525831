import * as React from 'react';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import { useGetAvailableData } from './useGetAvailableData';
import { CustomSelect, InputField1, TextArea1 } from 'src/components/inputFields/InputField';
import { PrimaryButton, PrimaryButtonOutlined, SecondaryButton } from 'src/components/buttons/MyButton';
import { CustomDate } from 'src/components/date_picker/CustomDatePicker';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Validator from 'validatorjs';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { decrement, increment, valToZero } from 'src/redux/tabSlice';
import { addmanifestData, getLocationData, updatemanifestData } from 'src/redux/manifestSlice';
import { useFetchCategoryData } from '../equipment_category/usefetchEquipmentData';
import {
  Box,
  Chip,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import UploadModal from './UploadModal';
import ExportModal from './ExportModal';
import ShowBagListmodal from './ShowBagListModal';
import ShowListmodal from './ShowListModal';
import ShowAuditModal from './ShowAuditModal';
import UploadAuditModal from './UploadAuditModal';
import MoreAvaialableModal from './MoreAvaialableModal';
import YourViewPageComponent from './ExportModal';
import ExportRopeKitModal from './ExportModal';
import DataTable from 'src/components/datatable/DataTable';
import { CustomToolBarButton } from './CustomToolBarButton';
import MoreAvailableBagModal from './MoreAvailableBagModal';
import MoreAvailableEquipmentModal from './MoreAvailableEquipmentModal';
import dayjs from 'dayjs';
import { formatString } from 'src/utils/formateString';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Manifest() {
  const [value, setValue] = React.useState(0);
  const [searchparams] = useSearchParams();
  const manifestId = searchparams.get('id');
  const manifestList = useSelector((state) => state.manifest.manifestData);
  const [Manifest, setManifest] = React.useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchBags, setSearchBags] = React.useState('');
  const [availableList, setAvailableList] = React.useState(false);
  const [openEquipmentReplace, setOpenEquipmentReplace] = React.useState(false);
  const [type, setType] = React.useState(null);
  const [equipmentCategory, setEquipmentCategory] = React.useState();
  const [showBagList, setShowBagList] = React.useState(false);
  const [BagManifest, setBagManifest] = React.useState();
  const [showList, setShowList] = React.useState(false);
  const [exportModal, setExportModal] = React.useState(false);
  const [auditModal, setAuditModal] = React.useState(false);
  const [showAudit, setShowAudit] = React.useState(false);
  const [uploadRope, setUploadRope] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [equipment, setequipment] = React.useState();
  const [equipments, setEquipments] = React.useState(null);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [tasks, setTasks] = React.useState([
    {
      id: 1,
      status: 'equipment',
      time: '8 hrs',
      days: '5 days left',
    },
    {
      id: 2,
      status: 'Block 2',
      time: '6 hrs',
      days: '6 days left',
      done: false,
    },
    {
      id: 3,
      status: 'Manifest',
      time: '13 hrs',
      days: '4 days left',
    },
    {
      id: 4,
      status: 'equipment',
      time: '22 hrs',
      days: '2 days left',
      done: true,
    },
    {
      id: 5,
      status: 'Block 2',
      time: '2 hrs',
      days: '1 day left',
      newOrder: true,
      done: false,
    },
    {
      id: 6,
      status: 'Manifest',
      time: '20 hrs',
      days: '11 days left',
      done: true,
    },
    {
      id: 5,
      status: 'Delivered',
      time: '2 hrs',
      days: '1 day left',
      done: false,
    },
  ]);
  const [listName, setListName] = React.useState('');
  const [bags, setBags] = React.useState(null);
  const [bag, setBag] = React.useState();

  const tabCount = useSelector((state) => state.tab.tabCount.toString());
  const savedEquipment = useSelector((state) => state.equipment.savedEquipment);
  const loctionList = useSelector((state) => state.manifest.locationData);
  const clientList = useSelector((state) => state.manifest.clientData);
  const lastInspectionDate = useSelector((state) => state.manifest.lastInspectionDate);
  const nextInspectionDate = useSelector((state) => state.manifest.nextInspectionDate);
  const { equipmentCategoryDatas, fetchEquipCatData } = useFetchCategoryData();
  const menus = ['Basic Details', 'Add Bags', 'Add Equipment', 'Reports'];

  const handleChange = (event, newValue) => {
    if (value <= newValue) {
      return;
    } else {
      setValue(newValue);
    }
  };
  const {
    availableBags,
    availableEquipment,
    fetchAvaialbleBags,
    fetchAvaialbleEquipments,
    manifestName,
    setManifestName,
    location,
    setLocation,
    jobNo,
    setJobNo,
    client,
    setClient,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    editData,
    fetchEquipData,
    getSingleManifestData,
    comments,
    setComments,
    getMoreAvailabledataManifestData,
    availableManifestData,
    getLocationList,
    getClientList
  } = useGetAvailableData();

  React.useEffect(() => {
    if (lastInspectionDate) {
      setStartDate(dayjs(lastInspectionDate))
      setEndDate(dayjs(nextInspectionDate))
    }
    if (manifestId == null) {
      setStartDate(dayjs(new Date()))
      setEndDate(dayjs(new Date()).add(6, 'month'))
    }
  }, [lastInspectionDate])

  const newManifest = {
    ManifestName: manifestName,
    Location: location,
    JobNo: jobNo,
    Client: client,
    Comments: comments,
    LastInspectionDate: startDate.format('YYYY-MM-DD'),
    NextInspectionDate: endDate.format('YYYY-MM-DD'),
  };

  const handleAddManifestSingle = async (tab) => {
    const validationRules = {
      ManifestName: 'required',
      Location: 'required',
      // Client: 'required',
      LastInspectionDate: 'required|date',
    };

    if (manifestId !== null) {
      newManifest.ManifestId = manifestId;
    }

    const validation = new Validator(newManifest, validationRules);
    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData?.map((errorArray) => {
        errorArray?.map((error) => {
          if (error.includes('ManifestName')) {
            toast.error(`Manifest Name is required. Please enter a value.`);
          } else if (error.includes('LastInspectionDate')) {
            toast.error(`Last Inspection Date is required. Please enter a value.`);
          } else {
            // Display other error messages as they are
            toast.error(`${error}`);
          }
        });
      });
    } else {
      // Generate a UUID
      const newManifestId = uuidv4();
      newManifest.ManifestId = manifestId ? manifestId : newManifestId;
      const response = manifestId
        ? await axios.post(`${ApiUrl2}api/Manifest/UpdateSingleManifestData`, newManifest)
        : await axios.post(`${ApiUrl2}api/Manifest/InsertSingleManifestData`, newManifest);

      if (response.status === 200) {
        await dispatch({ type: increment });
        setValue(tab);
        const parseData = JSON.parse(response.data);

        const StartdateString = newManifest.StartDate;
        const StartdateObj = new Date(StartdateString);
        const formattedStartDate = StartdateObj.toLocaleDateString('en-UK', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });

        parseData.StartDate = formattedStartDate;

        const CloseDateString = newManifest.CloseDate;
        const CloseDateObj = new Date(CloseDateString);
        const formattedCloseDate = CloseDateObj.toLocaleDateString('en-UK', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        });

        parseData.CloseDate = formattedCloseDate;
        parseData.id = parseData.ManifestId;
        navigate(`/dashboard/ropekit/?id=${parseData.ManifestId}`);
        if (manifestId === null) {
          // setEditData(parseData);
          await dispatch({ type: addmanifestData, payload: parseData });
        } else {
          await dispatch({ type: updatemanifestData, payload: parseData });
        }
      }
    }
  };

  const ManifestAccordion = ({ type, data }) => {
    const availableListModal = () => {

      const menifestrequremnt = async () => {
        const response = await axios.get(`${ApiUrl2}api/Manifest/GetReplacementBags?manifestId=${manifestId}`);
        if (response.status === 200) {
          const bagData = JSON.parse(response.data)
          const rows = bagData?.map((bag, index) => ({
            id: index,
            BagId: bag?.BagId,
            BagName: bag?.BagName,
            BagDescription: bag?.BagDescription,
            BagReplacement: bag?.BagReplacement,
          }));
          console.log(rows)
          setType(rows);
          setAvailableList(true);
          setListName('Bag');
        } else {
          console.log("");
        }
      }

      menifestrequremnt();
    };

    const columns = [
      {
        field: 'BagName',
        headerName: 'Bag Name',
        width: 170,
        renderCell: (params) => (
          <div>
            {params.row.BagName}
          </div>
        ),
        valueGetter: (params) => `${params.row.BagName || ''}`,
        sortable: true,
      },
      {
        field: 'BagDescription',
        headerName: 'Bag Description',
        width: 230,
        valueGetter: (params) => `${params.row.BagDescription || ''}`,
        sortable: true,
      },
      {
        field: 'BagState',
        headerName: 'Status',
        width: 170,
        valueGetter: (params) => `${(params.row.BagState === "") ? "IS" : formatString(params.row.BagState)}`,
        sortable: true,
      },
    ];



    return (
      <>
        <div className="container">
          <div className="row">
            {/* {type?.slice(0, 3)?.map((task, i) => (
              <div className="col-sm-4" key={i} id={task.id} draggable>
                <div className="card">
                  <Typography sx={{ padding: '16.5px 14px !important' }}>
                    {' '}
                    {data === 'bag' ? task.BagName : task.EquipmentSknNumber}
                  </Typography>
                </div>
              </div>
            ))} */}
            <DataTable
              tableName='bag'
              columns={columns}
              rows={type || []}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              renderEmptyCell={() => <h1>No data Found</h1>}
              toolbarNotRequired
              filterMode="client"
              height={320}
            />
            <div className="d-flex justify-content-end mt-2">
              {type?.filter(item => item?.BagState?.toUpperCase() === "OUT OF SERVICE").length > 0 && (
                <PrimaryButton title={`Get Replacement`} onClick={() => availableListModal()} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const SearchItem = () => {
    if (searchBags?.length === 0) {
      setShowBagList(true);
    } else if (searchBags?.length > 0) {
      const searchHere = bags.filter((x) => x.BagName.toLowerCase().includes(searchBags.toLowerCase()));
      if (searchHere.length !== 0) {
        setBags(searchHere);
      }
      setShowBagList(true);
    }
  };

  const AvailablequipmentView = ({ type, data }) => {

    const availableListModal = () => {

      const menifestrequremnt = async () => {
        const response = await axios.get(`${ApiUrl2}api/Manifest/GetReplacement?manifestId=${manifestId}`);
        if (response.status === 200) {
          const EquipmentData = JSON.parse(response.data)
          const rows = EquipmentData?.map((equipment, index) => ({
            id: index,
            NonConformantEquipmentId: equipment?.NonConformantEquipmentId,
            NonConformantSknNumber: equipment?.NonConformantSknNumber,
            // EquipmentDescription: equipment?.EquipmentDescription,
            SupplierDescription: equipment?.SupplierDescription,
            EquipmentCategoryName: equipment?.EquipmentCategoryName,
            AvailableReplacements: equipment?.AvailableReplacements,
          }));
          console.log(rows)
          setType(rows);
          setOpenEquipmentReplace(true);
          setListName('Equipment');
        } else {
          console.log("hello");
        }
      }

      menifestrequremnt();
    };

    const columns = [
      {
        field: 'EquipmentSknNumber',
        headerName: 'Equipment SKN Number',
        width: 200,
        renderCell: (params) => (
          <div>
            {params.row.EquipmentSknNumber}
          </div>
        ),
        valueGetter: (params) => `${params.row.EquipmentSknNumber || ''}`,
        sortable: true,
      },
      {
        field: 'EquipmentCategoryName',
        headerName: 'Equipment Category Name',
        width: 200,
        valueGetter: (params) => `${params.row.EquipmentCategoryName || ''}`,
        sortable: true,
      },
      {
        field: 'SupplierDescription',
        headerName: 'Supplier Description',
        width: 200,
        valueGetter: (params) => `${params.row.SupplierDescription || 'NA'}`,
        sortable: true,
      },
      {
        field: 'EquipmentState',
        headerName: 'Status',
        width: 170,
        valueGetter: (params) => `${(params.row.EquipmentState === "") ? "IS" : formatString(params.row?.EquipmentState)}`,
        sortable: true,
      },
    ];

    return (
      <>
        <div className="container">
          <div className="row">
            {/* <TableContainer > */}
            <DataTable
              tableName='Equipment'
              columns={columns}
              rows={savedEquipment || []}
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              renderEmptyCell={() => <h1>No data Found</h1>}
              toolbarNotRequired
              filterMode="client"
              height={320}
            />
            <div className="d-flex justify-content-end  mt-3">
              {savedEquipment?.filter(item => item?.EquipmentState?.toUpperCase() === "OUT OF SERVICE").length > 0 && (
                <PrimaryButton
                  title={`Get Replacement`}
                  onClick={() => availableListModal()}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const searchEquipment = () => {
    if (equipmentCategory !== '') {
      const searchHere = equipments.filter((x) => x.EquipmentCategoryName === equipmentCategory);
      // console.log(searchHere);
      if (searchHere.length !== 0) {
        setEquipments(searchHere);
      }
    }
    setShowList(true);
  };
  const AddExcelNCRRope = (rope) => {
    setUpload(true);
    if (rope === 'rope') {
      setUploadRope(true);
    }
    //
  };
  React.useEffect(() => {
    if (equipmentCategoryDatas === null) {
      fetchEquipCatData();
    } else {
      setEquipmentCategory(equipmentCategoryDatas?.[0]?.EquipmentCategoryName);
    }
  }, [equipmentCategoryDatas]);
  React.useEffect(() => {
    if (manifestList === null) {
      fetchEquipData();
      // fetchAvaialbleEquipments();
    }
  }, [manifestList]);
  React.useEffect(() => {
    if (tabCount === '1') {
      fetchAvaialbleBags();
    }
  }, [tabCount]);

  React.useEffect(() => {
    if (tabCount === '2') {
      fetchAvaialbleEquipments();
    }
  }, [tabCount]);
  React.useEffect(() => {
    if (manifestId !== null) {
      getSingleManifestData(manifestId);
    }
  }, [manifestId]);

  React.useEffect(() => {
    return () => {
      dispatch({ type: valToZero });
    };
  }, []);

  React.useEffect(() => {
    if (equipmentCategory !== '') {
      if (manifestId !== null) {
        getSingleManifestData(manifestId);
      }
      fetchAvaialbleEquipments();
    }
  }, [equipmentCategory]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   searchEquipment();
  // }, [equipments]);

  // console.log(bags);/

  // useEffect(() => {
  //   if (searchBags?.length > 0) {
  //     const searchHere = bags.filter((x) => x.BagName.toLowerCase().includes(searchBags.toLowerCase()));
  //     console.log(searchHere);
  //   }
  // }, [searchBags]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (loctionList !== null) {
      getLocationList();
    }
  }, []);

  React.useEffect(() => {
    if (clientList !== null) {
      getClientList();
    }
  }, []);


  return (
    <div className="container p-3">
      <div className="row Tabs_custom">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ ml: 4, mb: 3, color: "gray" }}>
            <h3>{`RopeKit Name : ${manifestName || "SKN000"}`}</h3>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{ width: '100%' }}
              className="Tabs_custom w-100"
              value={value}
              onChange={handleChange}
            >
              {menus.map((item, i) => (
                <Tab key={i} label={item} {...a11yProps(i)} />
              ))}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div exit={{ scale: 0 }} initial={{ scale: 0 }} animate={{ scale: 1 }} className="container">
              <div
                exit={{ scale: 0 }}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                className="row mt-4 d-flex align-items-center justify-content-center"
              >
                <div className="col-sm-6">
                  <InputField1
                    id="RopeKitName"
                    name="RopeKitName"
                    margin={'normal'}
                    autoComplete={'on'}
                    label="RopeKit Name"
                    value={manifestName || "SKN000"}
                    disabled={true}
                    onChange={(e) => setManifestName(e.target.value)}
                    fullWidth
                  />
                  <InputField1
                    id="JobNumber"
                    name="JobNumber"
                    margin={'normal'}
                    autoComplete={'on'}
                    label="Job Number"
                    value={jobNo}
                    onChange={(e) => setJobNo(e.target.value)}
                    fullWidth
                  />
                  {/* <InputField1
                    margin={'normal'}
                    name="name"
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    fullWidth
                  /> */}
                  <CustomSelect
                    id="Location"
                    name="Location"
                    margin="normal"
                    autoComplete={'on'}
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    menus={
                      loctionList?.length > 0 ? (
                        loctionList.map((data, i) => (
                          <MenuItem key={i} value={data}>
                            {data}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No data available</MenuItem>
                      )
                    }
                  />
                  {/* <InputField1
                    margin={'normal'}
                    name="name"
                    label="Client"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                    fullWidth
                  /> */}
                  <CustomSelect
                    id="Client"
                    name="Client"
                    margin="normal"
                    autoComplete={'on'}
                    label="Client"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                    menus={
                      clientList?.length > 0 ? (
                        clientList?.map((data, i) => (
                          <MenuItem key={i} value={data}>
                            {data}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No data available</MenuItem>
                      )
                    }
                  />
                  <CustomDate
                    id="LastInspDate"
                    name="LastInspDate"
                    label="Last Inspection date"
                    autoComplete={'on'}
                    margin={'normal'}
                    style={{ marginTop: '10px' }}
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e)
                      setEndDate(dayjs(e).add(6, 'month'))
                    }}
                    minDate={dayjs(new Date())}
                  />
                  <CustomDate
                    id="NextInspDate"
                    name="NextInspDate"
                    label="Next Inspection date"
                    autoComplete={'on'}
                    margin={'normal'}
                    style={{ marginTop: '14px' }}
                    value={endDate}
                    onChange={(e) => setEndDate(e)}
                    minDate={startDate}
                  />{' '}
                  <TextArea1
                    id="Comments"
                    name="Comments"
                    margin={'normal'}
                    autoComplete={'on'}
                    label="Comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    fullWidth
                  />
                  <PrimaryButton
                    title="Next"
                    fullWidth
                    size="large"
                    classNames="mt-4"
                    id="nextButton"
                    onClick={() => handleAddManifestSingle(1)}
                  />
                </div>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="row mt-4 d-flex align-items-center justify-content-center">
              <div className="col-sm-6">
                <div className="d-flex align-items-center justify-content-between" style={{ gap: '15px' }}>
                  <InputField1
                    id="bags"
                    name="bags"
                    classNames="w-100"
                    autoComplete={'on'}
                    label="Bags"
                    value={searchBags}
                    onChange={(e) => setSearchBags(e.target.value)}
                  />
                  <PrimaryButton id="searchButton" title="Search" size="large" onClick={SearchItem} />
                </div>
              </div>
              <div className="col-sm-7">
                <div className="mt-4">
                  <ManifestAccordion type={BagManifest} data="bag" />
                </div>
                <div style={{ gap: '20px' }} className="d-flex align-items-center w-100">
                  <SecondaryButton
                  id="bagBackButton"
                    title="Back"
                    fullWidth
                    size="large"
                    classNames="mt-4"
                    onClick={() => {
                      dispatch({ type: decrement });
                      setValue(0);
                    }}
                  />
                  <PrimaryButtonOutlined
                  id="bagNextButton"
                    title="Next"
                    fullWidth
                    size="large"
                    classNames="mt-4"
                    onClick={() => {
                      if (BagManifest?.length === 0) {
                        toast.error(`Please add bags to proceed further!`);
                      } else {
                        dispatch({ type: increment });
                        setValue(2);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div className="row mt-2 d-flex align-items-center justify-content-center">
              <div className="col-sm-7">
                <div className="d-flex align-items-center justify-content-between" style={{ gap: '15px' }}>
                  <CustomSelect
                    id="EquipmentCategory"
                    name="EquipmentCategory"
                    autoComplete={'on'}
                    margin="normal"
                    label="Equipment Category"
                    value={equipmentCategory}
                    onChange={(e) => setEquipmentCategory(e.target.value)}
                    menus={
                      equipmentCategoryDatas?.length > 0 &&
                      equipmentCategoryDatas.map((data, i) => (
                        <MenuItem key={i} value={data.EquipmentCategoryName}>
                          <Grid style={{ padding: "1px 0px" }}>
                            <Typography fontWeight={700} color={"#007C7C"}>{data.EquipmentCategoryName}</Typography>
                            <Typography>Supplier Description : {data.SupplierDescription || "NA"}</Typography>
                          </Grid>
                        </MenuItem>
                      ))
                    }
                  />
                  <PrimaryButton id="equipmentSeaRchButton" title="Search" size="large" onClick={searchEquipment} />
                </div>
              </div>
              <div className="col-sm-8 mt-4">
                <div>
                  <AvailablequipmentView type={Manifest} data="equipment" />
                </div>
                <div style={{ gap: '20px' }} className="d-flex align-items-center w-100">
                  <SecondaryButton
                  id='equipmentBackButton'
                    title="Back"
                    fullWidth
                    size="large"
                    classNames="mt-4"
                    onClick={() => {
                      dispatch({ type: decrement });
                      setValue(1);
                    }}
                  />
                  <PrimaryButtonOutlined
                   id='equipmentNextButton'
                    title="Next"
                    fullWidth
                    size="large"
                    classNames="mt-4"
                    onClick={() => {
                      if (equipmentCategoryDatas?.length === 0) {
                        toast.error(`Please add equipments to proceed further!`);
                      } else {
                        dispatch({ type: increment });
                        setValue(3);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <div className=" row  d-flex align-items-center justify-content-center mt-4">
              <div className="col-sm-6">
                <SecondaryButton
                id='reportBackButton'
                  title="Back"
                  fullWidth
                  classNames="my-4"
                  onClick={() => {
                    dispatch({ type: decrement });
                    setValue(2);
                  }}
                />
              </div>
            </div>
            <div className="row  d-flex align-items-center justify-content-center">
              <div className="col-sm-6">
                <PrimaryButton
                  id='Download_Pre_filled_Forms'
                  title="Download Pre-filled Forms"
                  fullWidth
                  size="large"
                  classNames="mt-4"
                  onClick={() => setExportModal(true)}
                />
               
                <PrimaryButtonOutlined
                 id='Upload_Completed_SKNF021'
                  title="Upload Completed SKNF021"
                  fullWidth
                  size="large"
                  classNames="mt-4"
                  onClick={() => AddExcelNCRRope('rope')}
                />{' '}
                 <PrimaryButtonOutlined
                id='Upload_Completed_SKNF018'
                  onClick={() => AddExcelNCRRope()}
                  title="Upload Completed SKNF018"
                  fullWidth
                  size="large"
                  classNames="mt-4"
                />{' '}
                <PrimaryButtonOutlined
                id='View_Audit_History'
                  title="View Audit History "
                  fullWidth
                  size="large"
                  classNames="mt-4"
                  onClick={() => setShowAudit(true)}
                /> {' '}
                 <PrimaryButtonOutlined
                 id='Upload_Audit_Information'
                  title="Upload Audit Information "
                  fullWidth
                  size="large"
                  classNames="mt-4"
                  onClick={() => setAuditModal(true)}
                />
                {/* {upload && <UploadModal />} */}
                <SecondaryButton
                id='Close_Manifest'
                  title="Close Manifest"
                  fullWidth
                  size="large"
                  classNames="mt-4"
                  onClick={() => navigate('/dashboard/app')}
                />
              </div>
            </div>
          </CustomTabPanel>
        </Box>
      </div>

      <UploadModal
        open={upload}
        setOpen={setUpload}
        fetchEquipData={fetchEquipData}
        uploadRope={uploadRope}
        setUploadRope={setUploadRope}
        setValue={setValue}
        manifestId={manifestId}
      />
      <ExportRopeKitModal open={exportModal} setOpen={setExportModal} bagdata={BagManifest} equipmentData={savedEquipment} manifestName={manifestName} />
      <ShowBagListmodal
        open={showBagList}
        setOpen={setShowBagList}
        setManifestName={setManifestName}
        // tasks={tasks}
        // setTasks={setTasks}
        searchBags={searchBags}
        availableBags={availableBags}
        editData={editData}
        tabCount={tabCount}
        manifestId={manifestId}
        bags={bags}
        setBags={setBags}
        BagManifest={BagManifest}
        setBagManifest={setBagManifest}
        bag={bag}
        setBag={setBag}
      />

      <ShowListmodal
        open={showList}
        setOpen={setShowList}
        tasks={tasks}
        setTasks={setTasks}
        searchBags={searchBags}
        availableBags={availableBags}
        editData={editData}
        tabCount={tabCount}
        manifestId={manifestId}
        bags={bags}
        setBags={setBags}
        availableEquipment={availableEquipment}
        setEquipments={setEquipments}
        equipments={equipments}
        setEquipmentCategory={setEquipmentCategory}
        equipmentCategoryDatas={equipmentCategoryDatas}
        equipmentCategory={equipmentCategory}
        searchEquipment={searchEquipment}
        fetchAvaialbleEquipments={fetchAvaialbleEquipments}
        Manifest={Manifest}
        setManifest={setManifest}
        equipment={equipment}
        setequipment={setequipment}
      />

      {/* <MoreAvaialableModal
        open={availableList}
        setOpen={setAvailableList}
        type={type}
        availableManifestData={availableManifestData}
        listname={listName}
      /> */}

      <MoreAvailableBagModal
        open={availableList}
        setOpen={setAvailableList}
        manifestIdValue={manifestId}
        type={type}
        getSingleManifestData={getSingleManifestData}
      />
      <MoreAvailableEquipmentModal
        open={openEquipmentReplace}
        setOpen={setOpenEquipmentReplace}
        manifestIdValue={manifestId}
        type={type}
        getSingleManifestData={getSingleManifestData}
      />
      <UploadAuditModal open={auditModal} setOpen={setAuditModal} editData={editData} />
      <ShowAuditModal open={showAudit} setOpen={setShowAudit} editData={editData} />
    </div>
  );
}
