import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteBox({ open, setOpen, setDeleteId, url, dispatchData }) {
  const handleClose = () => {
    setOpen(false);
  };

  const DeleteEquipment = async () => {
    const response = await axios.get(`${ApiUrl2}${url}`);
    if (response.status === 200) {
      dispatchData();
      setDeleteId(null);
      handleClose();
      toast.success(`Ropekit list Deleted Successfully`);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <Typography variant="h4">Are you sure want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={DeleteEquipment} title="Delete" />
          <SecondaryButton onClick={handleClose} title="Cancel" />
        </DialogActions>
      </Dialog>
    </>
  );
}
