import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { useState } from 'react';
import { ColorButton, PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { Typography } from '@mui/material';
// import { DownloadSheet } from 'src/components/downloadSheet/DownloadSheet';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import Button from 'src/theme/overrides/Button';
import { useSearchParams } from 'react-router-dom';

export default function ExportRopeKitModal({ open, setOpen, editData, bagdata, equipmentData, manifestName}) {
  const [searchparams] = useSearchParams();
  const manifestId = searchparams.get('id');

  const handleClose = () => {
    setOpen(false);
  };
  const handleDownloadManifest = () => {
    fetch(`${ApiUrl2}api/Manifest/DownloadManifestExcel?manifestId=${manifestId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentDisposition = response.headers.get('content-disposition');
        const filename = `RopeKit_${manifestName}.xlsx`;

        response.blob().then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
        // Handle the error, e.g., show an error message to the user
      });
  };

  const handleDownloadNCR = () => {
    fetch(`${ApiUrl2}api/Manifest/DownloadNCRExcel?manifestId=${manifestId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentDisposition = response.headers.get('content-disposition');
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
        const filename = filenameMatch ? filenameMatch[1] : 'NCR_Register.xlsx';
        response.blob().then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
        // Handle the error, e.g., show an error message to the user
      });
  };

  const handleDownloadNCREquipmentAndBagDetailExcel= () => {
    fetch(`${ApiUrl2}api/Manifest/DownloadNCREquipmentAndBagDetailExcel?manifestId=${manifestId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const contentDisposition = response.headers.get('content-disposition');
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
        const filename = filenameMatch ? filenameMatch[1] : 'NCR_Warehouse_Report.xlsx';
        response.blob().then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        });
      })
      .catch((error) => {
        console.error('Error downloading file:', error.message);
        // Handle the error, e.g., show an error message to the user
      });
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h4">
            Export Ropekit
          </Typography>

          <div
            className="my-4 d-flex align-items-center justify-content-center gap-4"
            style={{ border: '2px solid var(--500)', height: '150px', borderRadius: '8px', overflow: 'auto' }}
          >
             <ColorButton
              variant="contained"
              component="label"
              onClick={handleDownloadManifest}
            >
              Download SKNF021
            </ColorButton>
            <ColorButton variant="contained" component="label" onClick={handleDownloadNCR}>
              Download SKNF018
            </ColorButton>
            {/* { (equipmentData?.filter(item => item?.EquipmentState?.toUpperCase() === "OUT OF SERVICE").length > 0  ||   bagdata?.filter(item => item?.BagState?.toUpperCase() === "OUT OF SERVICE").length > 0 ) && */}
            <ColorButton variant="contained" component="label" onClick={ handleDownloadNCREquipmentAndBagDetailExcel}>
              Download Warehouse Report
            </ColorButton>
            {/* } */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
