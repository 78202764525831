import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, IconButton, InputAdornment } from '@mui/material';
import Iconify from '../../components/iconify/Iconify';
import { ApiUrl } from 'src/utils/ApiUrl';
import axios from 'axios';
import Validator from 'validatorjs';
import { toast } from 'react-toastify';
import { InputField1 } from 'src/components/inputFields/InputField';
import { PrimaryButton } from 'src/components/buttons/MyButton';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';

import { styled } from '@mui/material/styles';
const CustomFormControl = styled(FormControl)({
  '& label.Mui-focused': {
    color: `var(--500)`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `var(--500)`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `var(--500)`,
    },
    '&:hover fieldset': {
      borderColor: `var(--500)`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `var(--500)`,
    },
  },
});

export const CustomSelect = ({ value, onChange, menus, label, size, margin, demoArr, disabled }) => {
  return (
    <CustomFormControl fullWidth size={size && size} margin={margin && margin}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value && value}
        label={label}
        onChange={onChange && onChange}
        disabled={disabled && true}
      >
        {menus.map((menuItem, index) => (
          <MenuItem key={index} value={menuItem.value}>
            {menuItem.label}
          </MenuItem>
        ))}

        {demoArr &&
          demoArr?.length > 0 &&
          demoArr.map((data, i) => (
            <MenuItem key={i} value={data}>
              {data}
            </MenuItem>
          ))}
      </Select>
    </CustomFormControl>
  );
};

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const GenderArr = ['Male', 'Female', 'Other'];

  const handleClick = async () => {
    const checkData = {
      name: 'required',
      surname: 'required',
      email: 'required|email',
      gender: 'required',
      password: 'required|min:6',
      confirmPassword: 'required|same:password',
    };

    const registerData = { name, surname, email, gender, password, confirmPassword };

    const validation = new Validator(registerData, checkData);

    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
    } else {
      const response = await axios.post(`${ApiUrl}admin/register`, { name, surname, email, gender, password });
      if (response.status === 200) {
        toast.success('Registration Success');
        navigate('/dashboard', { replace: true });
      }
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Stack direction="row" spacing={2}>
          <InputField1
            name="name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            classNames="w-100"
          />
          <InputField1
            name="surname"
            label="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            classNames="w-100"
          />
        </Stack>
        <InputField1 name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
        <CustomSelect
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          menus={GenderArr.map((option) => ({ label: option, value: option }))}
          label="Gender"
        />

        <Stack direction="row" spacing={2}>
          <InputField1
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <InputField1
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            name="confirmPassword"
            label="Confirm Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <PrimaryButton title="Register" fullWidth size="large" onClick={handleClick} />
      </Stack>
    </>
  );
}
