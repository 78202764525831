export function formatString(input) {
    if (!input) return ''; 

    const words = input.trim().split(/\s+/);

    if (words.length === 1) {
        return words[0].toUpperCase();
    }
    
    return words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}