import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { useState } from 'react';
import { ColorButton, PrimaryButton, SecondaryButton } from 'src/components/buttons/MyButton';
import { Typography } from '@mui/material';
// import { DownloadSheet } from 'src/components/downloadSheet/DownloadSheet';
import axios from 'axios';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { decrement, increment, valToZero } from 'src/redux/tabSlice';
import { useGetAvailableData } from './useGetAvailableData';

export default function UploadModal({ open, setOpen, fetchEquipData, uploadRope, setUploadRope, setValue, manifestId }) {
  const [excelFiles, setExcelFiles] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    getSingleManifestData
  } = useGetAvailableData();

  const handleClose = () => {
    setOpen(false);
    setUploadRope(false);
    setExcelFiles([]);
  };

  const handleFileUpload = (event) => {
    const files = event.target.files;
    setExcelFiles([...excelFiles, ...files]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    excelFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    const handleEdit = (id) => {
      if (id) {
        navigate(`/dashboard/ropekit/?id=${id}`);
      } else {
        toast.error(`Please select row`);
      }
    };

    const ApiUrl = uploadRope ? `${ApiUrl2}api/Manifest/UploadSKNRegister` : `${ApiUrl2}api/Manifest/UploadNCRRegister`;

    try {
      const response = await axios.post(ApiUrl, formData);
      // console.log(response.data.response);
      if (response.status === 200) {
        if (uploadRope) {
          toast.success(response.data);
        } else {
          toast.success(response?.data?.Message);
          setTimeout(() => {
            if (manifestId) {
            handleEdit(response?.data?.ManifestId)
            }
          }, 1000)
        }
        fetchEquipData();
        if (uploadRope === false) {
          dispatch({ type: decrement });
          setValue(0);
        }
        if (manifestId) {
          getSingleManifestData(manifestId);
        }
        handleClose();
      }
      // console.log(response.data);

      // Handle success or display a success message
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.Message);
      }
      handleClose();
      console.error(error);
      // Handle error or display an error message
    }
  };

  // console.log(file);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h4">
            Add New {`${uploadRope ? 'Rope Access' : 'NCR Sheet'}`}
          </Typography>
          {/* <PrimaryButtonOutlined
            onClick={() => DownloadSheet('ncr', 'NCRUpload')}
            title="Download example Template"
            classNames="my-3"
          /> */}
          <div
            className="my-4 d-flex align-items-center justify-content-center"
            style={{ border: '2px solid var(--500)', height: '150px', borderRadius: '8px', overflow: 'auto' }}
          >
            {excelFiles.length > 0 ? (
              <ul style={{ listStyleType: 'none' }}>
                {excelFiles.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <SecondaryButton
                      classNames="ms-2 mt-2"
                      title="Remove"
                      type="button"
                      onClick={() => setExcelFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <ColorButton variant="contained" component="label">
                <input id='upload' name='upload' hidden accept=".xlsx, .xls" multiple={true} type="file" onChange={handleFileUpload} />
                Upload
              </ColorButton>
            )}
          </div>
          <div style={{ gap: '20px' }} className="d-flex align-items-center">
            <PrimaryButton onClick={handleSubmit} title="Upload" fullWidth size="large" classNames="mt-4" />
            <SecondaryButton title="Cancel" fullWidth size="large" classNames="mt-4" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
