import { configureStore } from '@reduxjs/toolkit';
import adminSlice from './adminSlice';
import tabSlice from './tabSlice';
import equipmentSlice from './equipmentSlice';
import manifestSlice from './manifestSlice';
import bagSlice from './bagSlice';
import equipmentCategorySlice from './equipmentCategorySlice';
import dashBoardSlice from './dashBoardSlice';
import purchaseOrderSlice from './purchaseOrderSlice';
import locationSlice from './locationSlice';
import supplierSlice from './supplierSlice';

export const store = configureStore({
  reducer: {
    admin: adminSlice,
    tab: tabSlice,
    equipment: equipmentSlice,
    manifest: manifestSlice,
    bag: bagSlice,
    equipmentCategory: equipmentCategorySlice,
    dashboard: dashBoardSlice,
    purchaseOrder: purchaseOrderSlice,
    location: locationSlice,
    supplier: supplierSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
