import { Link } from 'react-router-dom';
import './link.css';

export const Link1 = ({ to, title, className }) => {
  return (
    <Link to={to} className={`${className} link`}>
      {title}
    </Link>
  );
};
