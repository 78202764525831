import DataTable from 'src/components/datatable/DataTable';
import { Heading2, Heading3 } from 'src/components/headings/Heading';
import { Grid, Paper, Typography } from '@mui/material';
// import { useFetchCategoryData } from 'src/pages/equipment_category/usefetchEquipmentData';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditLocation from './EditSupplier';
import { useFetchSupplierData } from './useFetchSupplierData';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import EditSupplier from './EditSupplier';
import { deleteSupplier } from 'src/redux/supplierSlice';

export default function SupplierPage() {
  const [open, setOpen] = useState(false);
  const { supplierDatas, getSupplierList } = useFetchSupplierData();
  const [supplier, setSupplier] = useState(null);
  const { paginationModel, setPaginationModel } = useDefaultPagination();
  const [action , setAction]=useState("add");
  const dispatch = useDispatch();

  useEffect(() => {
    if (supplierDatas === null) {
      getSupplierList();
    }
  }, []);
  console.log(supplierDatas)

  const columns = [
    {
      field: 'SupplierName',
      headerName: 'Supplier Name',
      width: 270,
      valueGetter: (params) => `${params.row.SupplierName|| ''} `,
      sortable: true,
    },
    {
      field: 'SupplierDescription',
      headerName: 'Supplier Description',
      width: 270,
      valueGetter: (params) => `${params.row?.SupplierDetails?.detail || ''} `,
      sortable: true,
    },
    {
      field: 'SupplierCode',
      headerName: 'Supplier Code',
      width: 270,
      valueGetter: (params) => `${params.row.SupplierDetails?.code|| ''} `,
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      filterable: false,
      disableExport: true,
      renderCell: (params) => (
        <div>
          <EditIcon
            sx={{ color: `var(--500)`, cursor: 'pointer', marginRight: '8px' }}
            onClick={() => handleEdit(params.row)}
          />
          <DeleteIcon
            sx={{ cursor: 'pointer' }}
            color="error"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (data) => {
    setOpen(true);
    setSupplier({...data})
    setAction("edit")
    
  };

  const handleDelete = (id) => {
    // Implement your delete logic here
    console.log('Deleting row with id:', id);
    dispatch({
      type:  deleteSupplier,
      payload: id,
    });
  };

  const handleAdd = (id) => {
    // Implement your delete logic here
    setOpen(true)
    setAction("add")
    console.log('add', id);
  };

  const customOptions = {
    exportButton: false,
    search: false,
    filter: false,
    density: false,
    columnsButton: false,
    pagination: false,
  };

  return (
    <>
      <Paper className="p-3">
        <div className="mt-4">

          <Heading3 title="Supplier" classNames="mb-4" action={handleAdd}  buttonSize={"large"}/>
          {supplierDatas !== null && (
            <DataTable
             tableName='Location'
              columns={columns}
              rows={supplierDatas}
              toolbarNotRequired
              filterMode="Supplier"
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              customOptions={customOptions}
              isGenericPage={true}
            />
          )}
        </div>
      </Paper>
      <EditSupplier
        open={open}
        setOpen={setOpen}
        editData={supplier}
        setEditData={setSupplier}
        actionType={action}
      />
    </>
  );
}
