import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  supplierData: null,
};

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    getSupplierData: (state, action) => {
      state.supplierData = action.payload;
    },
    addSupplierData: (state, action) => {
      const newSuppliers = action.payload.map((supplier, index) => ({
        id: (state.supplierData?.length || 0) + index + 1, // Generate a new ID
        ...supplier,
      }));
      state.supplierData = [...(state.supplierData || []), ...newSuppliers];
    },
    updateSupplierData: (state, action) => {
      const { id, newData } = action.payload;
      state.supplierData = state.supplierData.map(supplier => {
        if (supplier.SupplierId === id) {
          return { ...supplier, ...newData };
        }
        return supplier;
      });
    },
    deleteSupplier: (state, action) => {
      state.supplierData = state.supplierData.filter((item) => item.id !== action.payload);
    },
  },
});

export const {
  getSupplierData,
  addSupplierData,
  updateSupplierData,
  deleteSupplier
} = supplierSlice.actions;

export default supplierSlice.reducer;
