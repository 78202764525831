import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { modalStyle } from 'src/utils/cssStyles';
import { ColorButton, PrimaryButton, PrimaryButtonOutlined, SecondaryButton } from 'src/components/buttons/MyButton';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import { DownloadSheet } from 'src/components/downloadSheet/DownloadSheet';

export default function UploadExcelModal({ open, setOpen, fetchEquipData }) {
  const handleClose = () => setOpen(false);
  const [excelFiles, setExcelFiles] = useState([]);

  const handleFileUpload = (event) => {
    const files = event.target.files;
    setExcelFiles([...excelFiles, ...files]);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    excelFiles.forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    try {
      const response = await axios.post(`${ApiUrl2}api/equipment/UploadEquipmentList`, formData);
      if (response.status === 200) {
        toast.success(response.data);
        fetchEquipData();
        handleClose();
      }
      fetchEquipData();
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.Message);
      }
      console.error(error);

      // Handle error or display an error message
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h4">
            Add New Excel Sheet
          </Typography>
          <PrimaryButtonOutlined
            onClick={() => DownloadSheet('equipment_excel', 'EquipmentUpload')}
            title="Download example Template"
            classNames="mt-3"
          />
          <div
            className="my-4 d-flex align-items-center justify-content-center"
            style={{ border: '2px solid var(--500)', height: '150px', borderRadius: '8px', overflow: 'auto' }}
          >
            {excelFiles.length > 0 ? (
              <ul style={{ listStyleType: 'none' }}>
                {excelFiles.map((file, index) => (
                  <li key={index}>
                    {file.name}
                    <SecondaryButton
                      classNames="ms-2 mt-2"
                      title="Remove"
                      type="button"
                      onClick={() => setExcelFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <ColorButton variant="contained" component="label">
                <input hidden accept=".xlsx, .xls" multiple={true} type="file" onChange={handleFileUpload} />
                Upload
              </ColorButton>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center mt-2" style={{ gap: '10px' }}>
            <PrimaryButton title="Submit" onClick={handleSubmit} />
            <SecondaryButton title="Cancel" onClick={handleClose} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
