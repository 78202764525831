import { useState } from 'react';

export const useDefaultPagination = () => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  return { paginationModel, setPaginationModel };
};
