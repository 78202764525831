import { Checkbox, FormControl, MenuItem, TextField, Tooltip } from '@mui/material';
import './input.css';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: `var(--500)`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `var(--500)`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `var(--500)`,
    },
    '&:hover fieldset': {
      borderColor: `var(--500)`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `var(--500)`,
    },
  },
});

const CustomTextArea = styled(TextField)({
  '& label.Mui-focused': {
    color: `var(--500)`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `var(--500)`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `var(--500)`,
    },
    '&:hover fieldset': {
      borderColor: `var(--500)`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `var(--500)`,
    },
  },
});

const CustomFormControl = styled(FormControl)({
  '& label.Mui-focused': {
    color: `var(--500)`,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: `var(--500)`,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `var(--500)`,
    },
    '&:hover fieldset': {
      borderColor: `var(--500)`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `var(--500)`,
    },
  },
});

export const InputField1 = ({
  id,
  name,
  label,
  value,
  onChange,
  classNames,
  InputProps,
  type,
  fullWidth,
  disabled=false,
  margin,
  placeholder,
  onKeyDown,
  autoComplete,
  InputLabelProps
}) => {
  return (
    <CustomTextField
      // InputProps={InputProps && InputProps}
      className={`${classNames && classNames} textField1`}
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      InputProps={InputProps && InputProps}
      type={type && type}
      fullWidth={fullWidth && fullWidth}
      margin={margin && margin}
      onKeyDown={onKeyDown}
      disabled={disabled}
      InputLabelProps={InputLabelProps}
      placeholder={placeholder && placeholder}
      autoComplete={autoComplete && autoComplete}
    />
  );
};

export const TextArea1 = ({
  id,
  name,
  label,
  value,
  onChange,
  classNames,
  InputProps,
  type,
  fullWidth,
  margin,
  placeholder,
  autoComplete,
}) => {
  return (
    <CustomTextArea
      // InputProps={InputProps && InputProps}
      multiline
      rows={2}
      className={`${classNames && classNames} textField1`}
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      InputProps={InputProps && InputProps}
      type={type && type}
      fullWidth={fullWidth && fullWidth}
      margin={margin && margin}
      placeholder={placeholder && placeholder}
      autoComplete={autoComplete && autoComplete}
    />
  );
};

export const CustomSelect = ({
  id,
  name,
  value,
  onChange,
  menus,
  label,
  size,
  margin,
  autoComplete,
  demoArr,
  icon=false,
  disabled,
  toolTipContent
}) => {
  const isIcon = icon? <span style={{cursor:"pointer"}}><Tooltip title={toolTipContent}><InfoOutlinedIcon style={{color:"red", width:"20px", background : 'white', cursor: 'pointer'}} /></Tooltip></span> :''
  return (
    <CustomFormControl fullWidth size={size && size} margin={margin && margin}>
      <InputLabel htmlFor={name} id={`${id}-label`} name={name}>
      {`${label}`}{isIcon}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        name={name}
        value={value && value}
        label={`${label}`}
        onChange={onChange && onChange}
        disabled={disabled && true}
        autoComplete={autoComplete && autoComplete}
      >
        {menus && menus}

        {demoArr &&
          demoArr?.length > 0 &&
          demoArr.map((data, i) => (
            <MenuItem key={i} value={data}>
              {data}
            </MenuItem>
          ))}
      </Select>
    </CustomFormControl>
  );
};

export const CustomCheck = ({ checked, onChange }) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      sx={{
        color: `var(--501)`,
        '&.Mui-checked': {
          color: `var(--501)`,
        },
      }}
    />
  );
};
