import DataTable from 'src/components/datatable/DataTable';
import { Heading2, Heading3 } from 'src/components/headings/Heading';
import { Grid, Paper, Typography } from '@mui/material';
// import { useFetchCategoryData } from 'src/pages/equipment_category/usefetchEquipmentData';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDefaultPagination } from 'src/utils/useDefaultPagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditLocation from './EditLocation';
import { useFetchLocationData } from './useFetchLocationData';
import { ApiUrl2 } from 'src/utils/ApiUrl';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function LocationTable() {
  const [open, setOpen] = useState(false);
  const { locationDatas,  getLocationList, addEditGenericList } = useFetchLocationData();
  const [location, setLocation] = useState(null);
  const { paginationModel, setPaginationModel } = useDefaultPagination();
  const [action , setAction]=useState("add");
  
  useEffect(() => {
    if (locationDatas === null) {
      getLocationList();
    }
  }, []);

  const columns = [
    {
      field: 'Location',
      headerName: 'Location',
      width: 240,
      valueGetter: (params) => `${params.row.location || ''} `,
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      filterable: false,
      disableExport: true,
      renderCell: (params) => (
        <div>
          <EditIcon
            sx={{ color: `var(--500)`, cursor: 'pointer' }}
            onClick={() => handleEdit(params.row)}
          />
          {/* <DeleteIcon
            sx={{ color: `var(--500)`, cursor: 'pointer' }}
            onClick={() => handleDelete(params.row)}
          /> */}
        </div>
      ),
    },
  ];

  const handleEdit = (data) => {
    setOpen(true);
    setLocation({...data})
    setAction("edit")
    
  };

  const handleDelete = (id) => {
    // Implement your delete logic here
    console.log('Deleting row with id:', id);
  };

  const handleAdd = (id) => {
    // Implement your delete logic here
    setOpen(true)
    setAction("add")
    console.log('add', id);
  };

  const customOptions = {
    exportButton: false,
    search: false,
    filter: false,
    density: false,
    columnsButton: false,
    pagination: false,
  };

  return (
    <>
      <Paper className="p-3">
        <div className="mt-4">

          <Heading3 title="Location" classNames="mb-4" action={handleAdd} />
          {locationDatas !== null && (
            <DataTable
             tableName='Location'
              columns={columns}
              rows={locationDatas}
              toolbarNotRequired
              filterMode="Location"
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              customOptions={customOptions}
              isGenericPage={true}
            />
          )}
        </div>
      </Paper>
      <EditLocation
        open={open}
        setOpen={setOpen}
        editData={location}
        setEditData={setLocation}
        actionType={action}
      />
    </>
  );
}
