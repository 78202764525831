import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './theme/Color.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRememberMe } from './utils/useCheckRemember';
import './App.css';

// ----------------------------------------------------------------------

export default function App() {
  const { CheckRememberme } = useRememberMe();
  // console.log(CheckRememberme);
  return (
    <Provider store={store}>
      <ToastContainer />
      <HelmetProvider>
        <BrowserRouter>
          <CheckRememberme />
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  );
}

//equipment upload excel remains
